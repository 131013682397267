import React, { useState, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const ThemeContext = React.createContext({ toggleColorMode: () => { } });

const ThemeContextProvider = ({ children }) => {
    const [mode, setMode] = useState('light');

    useEffect(() => {
        if (localStorage.getItem('isDark') === 'dark') {
            setMode('dark');
        }
    }, [])

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
                if (localStorage.getItem("isDark") === "dark") {
                    localStorage.setItem("isDark", "light")
                } else {
                    localStorage.setItem("isDark", "dark")
                }
            },
        }),
        [],
    );

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                },
            }),
        [mode],
    );

    return (
        <ThemeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ThemeContext.Provider>
    )
}

export { ThemeContext, ThemeContextProvider }
