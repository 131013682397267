import React from "react";
import Manual from "../components/Manual";

const ManualTransfer = () => {
    return (
        <div className="payment-container">
            <div className="card card-style pt-0" style={{background:"transparent"}}>
                <div className="content mt-3">
                    <div className="tabs tabs-pill" id="tab-group-2">
                        <Manual />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManualTransfer;
