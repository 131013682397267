import React, { useState, useEffect } from "react";
import {Button } from "antd";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import UserService from "../service/user.service";
import AuthService from "../service/auth.service";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";
import TransitionsModal from "../components/common/TransitionsModal";

const initialValues = {
  amount: "",
  customer_bank_account: "",
  bank_number: "",
};

const { Option } = Select;

const Withdraw = (walletInfo) => {
  const {t} = useTranslation();
  const [values, setValues] = useState(initialValues);
  const [cuBanks, setCuBanks] = useState([]);
  const [bankNumber, setBankNumber] = useState();
  const [totalBanks, setTotalBanks] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isTxnModal, setIsTxnModal] = useState(false);
  const [txnId, setTxnId] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [creditTypes, setCreditTypes] = useState([
    {
      value: "CA",
      label: t("Cash"),
    },
    {
      value: "CH",
      label: t("Chips"),
    },
  ]);
  const [selectedCredit, setSelectedCredit] = useState("");

  const navigate = useNavigate();

  const [validations, setValidations] = useState({
    amount: "",
    customer_bank_account: "",
    non_field_errors: "",
  });

  const {
    amount: amountVal,
    customer_bank_account: bank_Val,
    non_field_errors: nonVal,
    credit: creditValue
  } = validations;

  const getCustomBanklist = async () => {
    let totalbanks;
    let custombanks;
    try {
      totalbanks = await UserService.getBanks();
      custombanks = await UserService.getCustomBanks();
    } catch (err) {
      console.log("err ==> ", err);
    }
    setTotalBanks(totalbanks.data);
    let custombanklist = [];
    custombanks?.data?.map((game) => {
      if (game) {
        custombanklist.push({
          value: game?.id,
          label: game?.name,
          icon: game?.icon,
          number: game?.number,
        });
      }
    });
    setCuBanks(custombanklist);
  };

  const handleWithdraw = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (selectedCredit === "") {
      setValidations({
        ...validations,
        credit: "Please select credit type"
      })
    }

    try {
      console.log(values);
      const response = await UserService.withdraw(values);
      // Modal.success({
      //     content: 'Withdraw is successful',
      // });
      setTxnId(response.data.id);
      setIsTxnModal(true);
      setTimeout(() => {
        navigate("/account");
      }, 5000);
      setValues(initialValues);
    } catch (err) {
      console.log("err => ", err, values);
      let non_msg;
      let amount_msg;
      let bank_msg;


      if (err?.response?.data?.amount) {
        amount_msg = err.response.data.amount;
      }
      if (err?.response?.data?.customer_bank_account) {
        bank_msg = err.response.data.customer_bank_account;
      }
      if (err?.response?.data?.non_field_errors) {
        non_msg = err.response.data.non_field_errors;
      }
      setValidations({
        ...validations,
        amount: amount_msg,
        customer_bank_account: bank_msg,
        non_field_errors: non_msg,
        credit: selectedCredit === "" ? "Please select credit type" : ""
      });
    }
    setLoading(false);
  };

  const handleCreditChange = (e) => {
    setSelectedCredit(e.value);
    setValues({
      ...values,
      credit_type: e.value
    })
  };

  const handleChange = (e) => {
    const bankNumber = cuBanks.find((item) => item.value === e.value)?.number;
    setBankNumber(bankNumber);
    setValues((values) => {
      return { ...values, customer_bank_account: e.value };
    });
  };

  const handleNumChange = (e) => {
    const RE = /^\d*\.?\d{0,18}$/;
    if (RE.test(e.target.value) || e.target.value === "") {
      setValues((values) => {
        return { ...values, [e.target.name]: e.target.value };
      });
    }
  };

  const maxAmount = () => {
    if (walletInfo) {
      console.log(maxAmount);
      setValues((values) => {
        return { ...values, amount: walletInfo?.walletInfo?.balance, chips_balance: walletInfo?.walletInfo?.chips_balance  };
      });
    }
  };

  const getUserInfo = async () => {
    const user = await AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }
  };

  useEffect(() => {
    getCustomBanklist();
    getUserInfo();
  }, []);

  return (
    <div className="withdraw-wrap">
      <br />
      <div><img src={require("../images/Account.png")} alt="" style={{width:"100%", height: "auto"}} /></div>
      <br />
      <div className="w-full text-white bg-sky-500 p-2.5 flex items-center justify-between rounded-lg">
        <div className="w-1/2 flex justify-center items-center border-r flex-col py-2.5">
          <div className="text-sm">{t("Cash")} ( MYR )</div>
          <div className="text-2xl font-semibold mt-1">
            {currentUser ? walletInfo?.walletInfo?.balance : "0.00"}
          </div>
        </div>
        <div className="w-1/2 flex justify-center items-center flex-col py-2.5">
          <div className="text-sm">{t("Chips")} ( MYR )</div>
          <div className="text-2xl font-semibold mt-1">
            {currentUser ? walletInfo?.walletInfo?.chips_balance : "0.00"}
          </div>
        </div>
      </div>
      <form
        className="mt-3 demo-animation needs-validation m-0 relative"
        noValidate
      >
        {/* <select
                    className={`form-select rounded-xs w-full`}
                    id="c6"
                    aria-label="Floating label select example"
                    value={values?.customer_bank_account}
                    onChange={(e) => handleChange(e)}
                    name="customer_bank_account"
                >
                    <option value="">--Choose Your Account--</option>
                    {cuBanks.map((bank, idx) => {
                        return (
                            <option
                                className="text-black w-full"
                                value={bank?.id}
                                key={idx}
                            >
                                {bank?.name}
                            </option>
                        )
                    })}
                </select> */}
        <Select
          options={cuBanks}
          formatOptionLabel={(bank) => (
            <div className="flex items-center gap-1" key={bank?.id}>
              <img src={bank?.icon} alt="" className="w-10 h-10" />
              <span
                style={{ marginLeft: "auto", marginRight: "10px", flex: "0.8" }}
              >
                {bank?.label}
              </span>
              <span
                style={{ marginLeft: "auto", marginRight: "10px", flex: "0.1" }}
              >
                {bank?.number}
              </span>
            </div>
          )}
          onChange={handleChange}
          aria-label="Floating label select example"
          placeholder={`--${t("Choose")} ${t("Your_Account")}--`}
        />
        {bankNumber && (
          <div className="text-right text-sm">
            {t("Bank")} {t("Account_Number")}: {bankNumber}
          </div>
        )}

        <div
          className={`${bank_Val === "" ? "valid-feedback" : "invalid-feedback"
            } `}
        >
          {bank_Val}
        </div>

        <div style={{ marginTop: "1rem" }}>
          <Select
            onChange={handleCreditChange}
            defaultValue={selectedCredit}
            placeholder={`--${t("Choose")} ${t("Credit_Type")}--`}
            options={creditTypes}
          />
          <div
            className={`${creditValue === "" ? "valid-feedback" : "invalid-feedback"
              } `}
          >
            {creditValue}
          </div>
        </div>

        <div className="mt-3 position-relative">
          <input
            type="number"
            className={`form-control rounded-xs`}
            id="c3"
            placeholder={t("Amount")}
            onChange={handleNumChange}
            value={selectedCredit == "CA"?values.amount:values.chips_balance}
            name="amount"
          />
          <div
            className="absolute bg-sky-600 px-2 text-white font-semibold rounded-full top-3.5 right-2 cursor-pointer"
            onClick={maxAmount}
          >
            {t("MAX")}
          </div>
        </div>
        <div
          className={`${amountVal === "" ? "valid-feedback" : "invalid-feedback"
            } `}
        >
          {amountVal}
        </div>
        <div
          className={`${nonVal === "" ? "valid-feedback" : "invalid-feedback"
            } `}
        >
          {nonVal}
        </div>

        <button
          className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-3 h-40"
          onClick={handleWithdraw}
        >
          {t("Withdraw")}
        </button>
      </form>
      <TransitionsModal
        title="Congratulations!"
        style={{ top: "50px" }}
        open={isTxnModal}
        onClose={() => setIsTxnModal(false)}
        footer={[]}
      >
        <div className="text-lg font-semibold">Withdraw is successful.</div>
        <div className="font-semibold">
          Transaction Id: <span className="text-lime-700">{txnId}</span>
        </div>
        <div className="w-full flex justify-end gap-3 -mb-3">
          <Button onClick={() => setIsTxnModal(false)}>{t("Cancel")}</Button>
          <Button
            className="bg-sky-600 text-white"
            onClick={() => navigate("/account")}
          >
            {t("OK")}
          </Button>
        </div>
      </TransitionsModal>
      {isLoading && <Loading />}
    </div>
  );
};

export default Withdraw;
