import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import authService from "../../../service/auth.service";
import tokenService from "../../../service/token.service";
import { useTranslation } from "react-i18next";

const initialValues = {
  username: "",
  password: "",
  password2: "",
  cc: "60",
  mobile: "",
  referrer_code: "",
};

const SignUp = ({ setUserId }) => {
  const [values, setValues] = useState(initialValues);
  const [terms, setTerms] = useState(false);
  const [validations, setValidations] = useState({
    username: "",
    password: "",
    password2: "",
    cc: "",
    mobile: "",
    non_field_errors: "",
    referrer_code: "",
  });
  const [readOnly, setReadOnly] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const {t} = useTranslation();

  const handleChange = (e) => {
    setValues((values) => {
      return { ...values, [e.target.name]: e.target.value };
    });
  };

  const {
    username: nameVal,
    password: passVal,
    password2: pass2Val,
    cc: ccVal,
    mobile: mobileVal,
    terms: termsVal,
    non_field_errors: nonVal,
    referrer_code: referrer_code,
  } = validations;

  const submit = async (e) => {
    e.preventDefault();

    let response;
    try {
      response = await authService.register(values);
      setUserId(response?.data?.id);
      tokenService.setRegisterUserId(response?.data?.id);
      if (response?.data?.id) {
        tokenService.setRegisterUser(values);
        navigate("/verify");
      }
    } catch (err) {
      console.log("err => ", err);
      let username_message;
      let cc_message;
      let mobile_message;
      let password_message;
      let password2_message;
      let referrer_code_message;
      let non_field_errors_message;

      if (err?.response?.data?.username) {
        username_message = err.response.data.username;
      }
      if (err?.response?.data?.cc) {
        cc_message = err.response.data.cc;
      }
      if (err?.response?.data?.mobile) {
        mobile_message = err.response.data.mobile;
      }
      if (err?.response?.data?.password) {
        password_message = err.response.data.password;
      }
      if (err?.response?.data?.password2) {
        password2_message = err.response.data.password2;
      }
      if (err?.response?.data?.referrer_code) {
        referrer_code_message = err.response.data.referrer_code;
      }
      if (err?.response?.data?.non_field_errors) {
        non_field_errors_message = err.response.data.non_field_errors;
      }
      setValidations({
        ...validations,
        username: username_message,
        password: password_message,
        password2: password2_message,
        mobile: mobile_message,
        cc: cc_message,
        referrer_code: referrer_code_message,
        non_field_errors: non_field_errors_message,
      });
    }
  };

  const getReferralCode = () => {
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get("referrer");
    const localStorageCode = localStorage.getItem("referralCode");
    if (myParam || localStorageCode) {
      setValues((values) => {
        return { ...values, referrer_code: myParam || localStorageCode };
      });
      localStorage.removeItem("referralCode");
      setReadOnly(true);
    } else {
      setReadOnly(false);
    }
  };

  useEffect(() => {
    if (!terms) {
      const message = t("Please_Read_Terms_and_Conditions");
      setValidations({ ...validations, terms: message });
    } else {
      const message = "";
      setValidations({ ...validations, terms: message });
    }
  }, [terms]);

  useEffect(() => {
    getReferralCode();
  }, []);

  return (
    <div className="min-h-full d-flex flex-col justify-center">
      <div className="page-content footer-clear">
        <div className="card card-style" style={{ background: "transparent" }}>
         <img src={'../../f8logo.png'} className="F8logo" style={{width: '30%', background: 'transparent',alignSelf:"center"}} />
         <br/><br/><br/>
        <div className="test123" style={{display:"flex",background:"transparent",height:"50px",alignItems:"center",borderRadius: "20px",}}>
        <button
                onClick={() => navigate("/signin")}
                className="left-column"
                style={{
                  height: "40px",
                  width:"40%",
                  borderRadius: "20px",
                  fontWeight: "bold",
                  color: "white",
                  background:"rgb(173, 216, 230)",
                  marginLeft:"5%"
                  

                }}
              >
                {t("LOGIN")}
              </button>
              <button
                onClick={() => navigate("/signup")}
                className="right-column"
                style={{
                  height: "40px",
                  width:"40%",
                  borderRadius: "20px",
                  fontWeight: "bold",
                  color: "white",
                  background:"rgb(49, 140, 214)",
                  marginLeft:"auto",
                  marginRight:"5%"
                }}
              >
                {t("REGISTER")}
              </button>
              </div>
          <div className="content">
            <form onSubmit={submit}>
              {/* <h1
                className="mb-0 pt-2"
                style={{
                  fontSize: "30px",
                  fontWeight: "bold",
                  color: "rgb(49, 140, 214)",
                }}
              >
                Sign Up
              </h1> */}
              <br />
              <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                <div>
                  <i className="fa fa-user-circle-o text-md"></i>
                  <input
                    type="text"
                    className="form-control rounded-xs"
                    id="c1"
                    name="username"
                    placeholder={t("Username")}
                    onChange={handleChange}
                    autoCapitalize="none"
                  />
                  {/* <span style={{ fontSize: "12px" }}>(required)</span> */}
                </div>
                <div
                  className={`${
                    nameVal === "" ? "valid-feedback" : "invalid-feedback"
                  } `}
                >
                  {nameVal}
                </div>
              </div>
              <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                <div>
                  <i className="fa fa-asterisk font-13"></i>
                  <input
                    type="password"
                    name="password"
                    className="form-control rounded-xs"
                    id="c2"
                    placeholder={t("Password")}
                    onChange={handleChange}
                    autoCapitalize="none"
                  />
                  <label htmlFor="c2" className="color-theme">
                    {t("Password")}
                  </label>
                  {/* <span style={{ fontSize: "12px" }}>(required)</span> */}
                </div>
                <div
                  className={`${
                    passVal === "" ? "valid-feedback" : "invalid-feedback"
                  } `}
                >
                  {passVal}
                </div>
              </div>
              <div className="form-custom form-label form-border form-icon bg-transparent">
                <div>
                  <i className="fa fa-asterisk font-13"></i>
                  <input
                    type="password"
                    name="password2"
                    className="form-control rounded-xs"
                    id="c3"
                    placeholder={t("Confirm") + " " + t("Password")}
                    onChange={handleChange}
                    autoCapitalize="none"
                  />
                  <label htmlFor="c3" className="color-theme">
                    {t("Confirm") + " " + t("Password")}
                  </label>
                </div>
                <div
                  className={`${
                    pass2Val === "" ? "valid-feedback" : "invalid-feedback"
                  } `}
                >
                  {pass2Val}
                </div>
              </div>
              <div className="form-custom form-label form-border form-icon mb-4 bg-transparent">
                <div>
                  <i className="fa far fa-address-card"></i>
                  <input
                    type="text"
                    name="referrer_code"
                    className="form-control rounded-xs"
                    id="c3"
                    placeholder={t("Referral_Code")}
                    value={values.referrer_code}
                    onChange={handleChange}
                    readOnly={readOnly}
                    autoCapitalize="none"
                  />
                  <label htmlFor="c3" className="color-theme">
                    {t("Referral_Code")}
                  </label>
                </div>
                <div
                  className={`${
                    referrer_code === "" ? "valid-feedback" : "invalid-feedback"
                  } `}
                >
                  {referrer_code}
                </div>
              </div>
              <div className="phone-wrap">
                <PhoneInput
                  inputStyle={{
                    width: "100%",
                    borderRadius: "8px",
                    height: "40px",
                    background: "transparent",
                  }}
                  containerStyle={{ marginTop: "16px" }}
                  buttonStyle={{ borderRadius: "12px",background: "transparent",color:"transparent",border:"none"}}
                  country={"my"}
                  disableDropdown
                  onChange={(phone, country) => {
                    const reducedPhone = phone.replace(country.dialCode, "");
                    setValues({
                      ...values,
                      cc: country.dialCode,
                      mobile: reducedPhone,
                    });
                  }}
                />
                <div
                  className={`${
                    ccVal === "" ? "valid-feedback" : "invalid-feedback"
                  } `}
                >
                  {ccVal}
                </div>
                <div
                  className={`${
                    mobileVal === "" ? "valid-feedback" : "invalid-feedback"
                  } `}
                >
                  {mobileVal}
                </div>
                <div
                  className={`${
                    nonVal === "" ? "valid-feedback" : "invalid-feedback"
                  } `}
                >
                  {nonVal}
                </div>
              </div>
              <button
                className="zIndex1 w-100 btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"
                style={{
                  height: "50px",
                  borderRadius: "15px",
                  fontWeight: "bold",
                  color: "rgb(49, 140, 214)",
                }}
                type="submit"
              >
                {t("Sign_Up")}
              </button>
                <div className="text-center">
                  <a
                    // href="/forget-password""
                    onClick={() => navigate("/forget-password")}
                    className="font-15 color-theme opacity-60 pt-4 d-block"
                    style={{ fontWeight: "bold", color: "rgb(49, 140, 214)",cursor:"pointer" }}
                  >
                    {t("Forgot_Password")}
                  </a>
                </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
