import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import SignUp from "./pages/auth/signup/SignUp";
import Verify from "./pages/auth/signup/Verify";
import Save from "./pages/auth/signup/Save";
import { authRoutes } from "./routes";
import SignIn from "./pages/auth/SignIn";
import Promotions from "./pages/Promotions";
import Home from "./pages/Home";
import Footer from "./components/Footer";

const AuthLayout = ({ setAuth }) => {
  const [userId, setUserId] = useState(null);

  return (
    <div>
      <Routes>
        {authRoutes.map((route) => {
          const { path, element: Component } = route;
          return <Route key={path} path={path} element={<Component />} />;
        })}
        <Route path="/signin" element={<SignIn setAuth={setAuth} />} />
        <Route path="/signup" element={<SignUp setUserId={setUserId} />} />
        <Route path="/verify" element={<Verify userId={userId} />} />
        <Route path="/" element={<Home userId={userId} />} />
        <Route path="/promotions" element={<Promotions userId={userId} />} />
        <Route
          path="/save"
          element={<Save userId={userId} setAuth={setAuth} />}
        />
        <Route path="/*" element={<SignIn setAuth={setAuth} />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default AuthLayout;
