import "./pages.scss";
import React, { useState, useEffect } from "react";
import {Input, Drawer, Button } from "antd";
import { Icon } from "@iconify/react";
import QRCode from "qrcode";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import QRCodeSection from "./QRCodeSection";
import BankList from "../components/BankList";
import authService from "../service/auth.service";
import userService from "../service/user.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TransitionsModal from "../components/common/TransitionsModal";

const initialValue = {
  old_password: "",
  password: "",
  password2: "",
};

export default function Profile({
  getWalletInfo,
  walletInfo,
  setCurrentUserGlobal,
  setAuth,
}) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isBankLoading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [userId, setUserId] = useState();
  const [pwChangeModal, setPwChangeModal] = useState(false);
  const [values, setValues] = useState(initialValue);
  const [selectBank, showSelectBank] = useState(false);
  const [fetchedBank, setFetchedBank] = useState("");

  const [validations, setValidations] = useState({
    old_password: "",
    password: "",
    password2: "",
    refreshVal: "",
  });
  const [successChangePassword, setSuccessChangePassword] = useState(false);
  const [dateJonied, setDateJoined] = useState("");

  const [referCode, setReferCode] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [bankOpen, setBankOpen] = useState(false);
  const [bankModal, showBankModal] = useState(false);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const [accNumber, setAccNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const {t} = useTranslation();

  const handWithdraw = (url) => {
    navigate(url);
  };
  const getBankList = async () => {
    setIsLoading(true);
    const getBanks = await userService.getBanks();
    setBanks(getBanks?.data);
    setIsLoading(false);
  };

  const {
    old_password: currentPW,
    password: newPW,
    password2: newPWConfirm,
    refreshVal: refreshVal,
  } = validations;

  const getUserInfo = async () => {
    try {
      const userInfo = await userService.getBalance();
      const referralLink = userInfo.data.referral_link;
      const joinedTime = new Date(userInfo.data.user.date_joined);
      const year = joinedTime.getFullYear();
      const month = (joinedTime.getMonth() + 1).toString().padStart(2, "0");
      const day = joinedTime.getDate().toString().padStart(2, "0");
      const todayDate = `${year}-${month}-${day}`;
      setDateJoined(todayDate);
      setUserId(userInfo.data.user.id);
      return referralLink;
    } catch (err) {
      console.log(err);
    }
  };

  const generateQRCode = async (referral) => {
    try {
      await QRCode.toDataURL(referral);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeInputValue = (e) => {
    setValues((values) => {
      return { ...values, [e.target.name]: e.target.value };
    });
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      await authService.changePassword({ values, userId });
      setSuccessChangePassword(true);
      setPwChangeModal(false);
      Swal.fire({
        icon: "success",
        text: "The password modification has been completed successfully.",
      });
    } catch (err) {
      console.log(err.response.data);
      setValidations({
        ...validations,
        old_password: err.response.data?.old_password?.old_password,
        password: err.response.data?.password,
        password2: err.response.data?.password2,
        refreshVal: err.response.data?.detail,
      });
    }
  };

  const handleAddBank = async () => {
    try {
      if (selectedBank === "") return toast.error(t("Please_Select") + t("Bank"));
      if (accNumber === "") return toast.error(("Please_Enter") + ("Account_Number"));
      setLoading(true);

      const wallet = walletInfo.id;
      const user = walletInfo.user.id;

      const values = {
        wallet,
        user,
        number: accNumber,
        name: fullName,
        bank: selectedBank,
      };
      const result = await userService.addCustomerBank(values);
      if (!result.data.id) {
        setLoading(false);
        showBankModal(false);
        setBankOpen(false);
        return toast.error("Bank account with selected bank already exist!");
      }

      toast.success(t("Bank") + t("added successfully") + "!");
    } catch (err) {
      console.log(err);
      if (err.response) {
        const data = err.response.data;
        if (data.type) {
          return toast.error(`Type field is required!`);
        } else if (data.icon) {
          return toast.error(data.icon[0]);
        }
        return toast.error("Something went wrong! Please try again");
      }
    }
    setLoading(false);
    showBankModal(false);
    setBankOpen(false);
  };

  const handleAccountNumber = (e) => {
    const RE = /[0-9\b]$/;
    if (RE.test(e.target.value) || e.target.value === "") {
      setAccNumber(e.target.value);
    }
  };

  useEffect(() => {
    const initialSetting = async () => {
      try {
        setIsLoading(true);
        const referral = await getUserInfo();
        await generateQRCode(referral);

        const url = new URL(referral);
        const code = url.searchParams.get("referrer");
        setReferCode(code);

        const user = authService.getCurrentUser();
        if (userId) {
          setIsLoading(true);
        }
        if (user) {
          setCurrentUser(user);
          getWalletInfo();
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    initialSetting();
  }, [localStorage.getItem("user"), userId]);

  useEffect(() => {
    getBankList();
  }, []);

  return (
    <div className="pt-12 py-24">
      {isLoading && <Loading />}
      <div className="flex justify-between items-center">
        <div className="avatar_info">
          <img src="/test_avatar.png" width={60} className="avatar_image" />
          <div>
            <div className="text-lg font-semibold text-slate-900">
              {currentUser ? walletInfo?.username : t("No_Account")}
            </div>
            <div className="font-semibold flex gap-2 text-sm">
              <Input.Password
                value={referCode}
                style={{
                  width: "110px",
                  fontSize: "1rem",
                  border: "none",
                  padding: "0px",
                  outline: "none",
                }}
              />
            </div>
          </div>
        </div>
      <div className='flex items-center '>
        <Icon
          icon="tabler:logout"
          fontSize={24}
          className="text-red-600 cursor-pointer hover:text-sky-400 transition-all ms-1.5"
          onClick={() => {
            authService.logout();
            setCurrentUserGlobal(null);
            setAuth(false)
            navigate("/signin");
          }} 
        />
      </div>
      </div>
      <div className="text-left my-2 text-sm text-slate-500">
        <span style={{ fontWeight: "400" }}>{t("Timezone")} :</span> UTC/GMT +
        8:00(Malaysia)
      </div>
      <div className="text-slate-500 mt-3">
        <span className="font-semibold text-slate-900 text-lg">{t("Wallet")}</span>
      </div>
      <div className="w-full text-white bg-sky-500 p-2.5 flex items-center justify-between rounded-lg">
        <div className="w-1/2 flex justify-center items-center border-r flex-col py-2.5">
          <div className="text-sm">{t("Cash")} ( MYR )</div>
          <div className="text-2xl font-semibold mt-1">
            {currentUser ? walletInfo?.balance : "0.00"}
          </div>
        </div>
        <div className="w-1/2 flex justify-center items-center flex-col py-2.5">
          <div className="text-sm">{t("Chips")} ( MYR )</div>
          <div className="text-2xl font-semibold mt-1">
            {currentUser ? walletInfo?.chips_balance : "0.00"}
          </div>
        </div>
      </div>
      <div className="bg-white rounded-lg mt-3 p-2.5">
        <div className="flex justify-content-center">
          <div className="text-slate-500">
            <span className="font-semibold text-slate-900 text-lg">
              {t("Overview")}
            </span>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between py-2 rounded">
            <div className="flex flex-col items-center w-1/2 gap-2 border-r-2">
              <div className="text-l font-semibold text-slate-900">
                {dateJonied}
              </div>
              <div className="text-sm">{t("Date_Joined")}</div>
            </div>
            <div className="flex flex-col items-center w-1/2 gap-2">
              <div className="text-l font-semibold text-slate-900">0.00</div>
              <div className="text-sm">{t("Turnover")}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white rounded-lg mt-3 p-2.5">
        <div className="font-semibold text-slate-900 text-lg text-center">
          {t("Tools")}
        </div>
        <div className="grid grid-cols-2 rounded p-2">
          <div
            className="flex flex-col items-center cursor-pointer p-2 border-r-2"
            onClick={() => setBankOpen(true)}
          >
            <div className="bg-gradient-to-r from-violet-500 to-purple-500 w-max p-2 rounded-lg">
              <Icon
                icon="icon-park-outline:bank-card"
                fontSize={30}
                className="text-white"
              />
            </div>
            <div className="text-sm font-semibold text-slate-900 text-center">
              {t("Bank")} {t("Account")}
            </div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer p-2"
            onClick={() => setPwChangeModal(true)}
          >
            <div className="bg-gradient-to-r from-cyan-500 to-blue-500 w-max p-2 rounded-lg">
              <Icon
                icon="fluent:password-24-regular"
                fontSize={30}
                className="text-white"
              />
            </div>
            <div className="text-sm font-semibold text-slate-900 text-center">
              {t("Change_Password")}
            </div>
          </div>
          <div className="flex flex-col items-center cursor-pointer p-2 border-r-2">
            <a
              id="nav-homes"
              data-submenu="sub1"
              className={`list-group-item flex items-center flex-col `}
              style={{ border: "0px" }}
              onClick={() => {
                currentUser
                  ? handWithdraw("/app-accounts")
                  : handWithdraw("/signin");
              }}
            >
              <div className="bg-red-dark from-cyan-500 to-blue-500 w-max p-2 rounded-lg">
                <Icon icon="ion:qr-code" fontSize={30} className="text-white" />
                {/* <i className="fa bg-blue-dark shadow-bg shadow-bg-xs bi-house-fill">
                <i className="-ml-15 fa fa-qrcode color-white"></i>
              </i> */}
              </div>
              <div className="text-sm font-semibold text-slate-900 text-center">
                {t("Game_Account")}
              </div>
            </a>
          </div>

          <div className="flex flex-col items-center cursor-pointer p-2">
            <a
              id="nav-homes"
              data-submenu="sub1"
              className={`list-group-item flex items-center flex-col `}
              style={{ border: "0px" }}
              onClick={() => {
                currentUser ? handWithdraw("/rebate") : handWithdraw("/signin");
              }}
            >
              <div className="bg-green-dark from-cyan-500 to-blue-500 w-max p-2 rounded-lg">
                <Icon
                  icon="ph:money-fill"
                  fontSize={30}
                  className="text-white"
                />
              </div>
              <div className="text-sm font-semibold text-slate-900 text-center">
                {t("Rebate")}
              </div>
            </a>
          </div>
          <div className="flex flex-col items-center cursor-pointer p-2 border-r-2">
            <a
              id="nav-homes"
              data-submenu="sub1"
              className={`list-group-item flex items-center flex-col `}
              style={{ border: "0px" }}
              onClick={() => {
                currentUser
                  ? handWithdraw("/bethistory")
                  : handWithdraw("/signin");
              }}
            >
              <div className="bg-brown-dark from-cyan-500 to-blue-500 w-max p-2 rounded-lg">
                <Icon
                  icon="material-symbols:history"
                  fontSize={30}
                  className="text-white"
                />
              </div>
              <div className="text-sm font-semibold text-slate-900 text-center">
                {t("Bet_History")}
              </div>
            </a>
          </div>
          <div className="flex flex-col items-center cursor-pointer p-2">
            <a
              id="nav-homes"
              data-submenu="sub1"
              className={`list-group-item flex items-center flex-col `}
              style={{ border: "0px" }}
              onClick={() => {
                currentUser
                  ? handWithdraw("/referral")
                  : handWithdraw("/signin");
              }}
            >
              <div className="bg-yellow-dark from-cyan-500 to-blue-500 w-max p-2 rounded-lg">
                <Icon icon="ion:qr-code" fontSize={30} className="text-white" />
              </div>
              <div className="text-sm font-semibold text-slate-900 text-center">
                {t("QRCode")}
              </div>
            </a>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="w-full btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"
        onClick={() => {navigate('/history')}}
      >
        {t("See_Transaction_History")}
      </button>
      <TransitionsModal
      title={<div className="login-modal-title">{t("Change_Password")}</div>}
        centered
        open={pwChangeModal}
        onClose={() => {
          setPwChangeModal(false);
        }}
        footer={[]}
      >
        <form onSubmit={handleChangePassword}>
          <div className="form-custom form-label form-border form-icon bg-transparent">
            <div>
              <i className="fa fa-asterisk font-13"></i>
              <input
                name="old_password"
                type="password"
                className="form-control rounded-xs"
                id="c1"
                placeholder={t("Current_password")}
                onChange={handleChangeInputValue}
              />
              <label htmlFor="c1" className="color-theme">
                {t("Username")}
              </label>
              <span>{t("Required")}</span>
            </div>
            <div
              className={`${
                currentPW === "" ? "valid-feedback" : "invalid-feedback"
              } `}
            >
              {currentPW}
            </div>
            <div
              className={`${
                refreshVal === "" ? "valid-feedback" : "invalid-feedback"
              } `}
            >
              {refreshVal}
            </div>
          </div>
          <div className="form-custom form-label form-border form-icon bg-transparent">
            <div>
              <i className="fa fa-asterisk font-13"></i>
              <input
                name="password"
                type="password"
                className="form-control rounded-xs"
                id="c2"
                placeholder={t("New_password")}
                onChange={handleChangeInputValue}
              />
              <label htmlFor="c2" className="color-theme">
                {t("Password")}
              </label>
              <span>{t("Required")}</span>
            </div>
            <div
              className={`${
                newPW === "" ? "valid-feedback" : "invalid-feedback"
              } `}
            >
              {newPW}
            </div>
          </div>
          <div className="form-custom form-label form-border form-icon mb-4 bg-transparent">
            <div>
              <i className="fa fa-asterisk font-13"></i>
              <input
                name="password2"
                type="password"
                className="form-control rounded-xs"
                id="c2"
                placeholder={t("Confirm_your_new_password")}
                onChange={handleChangeInputValue}
              />
              <label htmlFor="c2" className="color-theme">
                {t("Password")}
              </label>
              <span>{t("Required")}</span>
            </div>
            <div
              className={`${
                newPWConfirm === "" ? "valid-feedback" : "invalid-feedback"
              } `}
            >
              {newPWConfirm}
            </div>
          </div>
          <button
            type="submit"
            className="w-100 btn btn-full gradient-highlight shadow-bg shadow-bg-s"
          >
            {t("Change_Password")}
          </button>
        </form>
      </TransitionsModal>

      <Drawer
        title={t("Referrel_Link")}
        placement={"bottom"}
        closable={true}
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        height={600}
        headerStyle={{ background: "white" }}
        bodyStyle={{ borderRadius: 0 }}
      >
        <div>
          <QRCodeSection />
        </div>
      </Drawer>

      <TransitionsModal
        open={bankModal}
        onClose={() => {
          showBankModal(false);
          setSelectedBank("");
        }}
        // onOk={handleAddBank}
        title={t("Add_Bank") +  t("Account")}
        okButtonProps={{ type: "primary", style: { background: "#4096ff" } }}
      >
        <div>
          <h4>{t("Full_Name")}</h4>
          <Input
            style={{ marginBottom: "1rem", marginTop: "6px" }}
            placeholder={t("Full_Name")}
            readOnly
            value={fullName}
          />
          <h4>{t("Select_Bank")}</h4>
          {/* <Select
            style={{ width: "100%", marginTop: "6px", marginBottom: "1rem" }}
            onChange={(e) => setSelectedBank(e)}
            placeholder="Select a Bank"
            value={selectedBank}
          >
            {banks.map((b, index) => (
              <Option value={b.id} key={index}>
                <div className="flex flex-row gap-2">
                  <img src={b.icon} className="w-12 h-8 rounded-sm" />
                  <p>{b.name}</p>
                </div>
              </Option>
            ))}
          </Select> */}
          <TransitionsModal
            open={selectBank}
            onClose={() => {
              showSelectBank(false);
              // setSelectedBank("");
            }}
            // onOk={() => {
            //   showSelectBank(false);
            //   setSelectedBank(fetchedBank.id);
            //   setSelectedBankName(fetchedBank.name);
            //   console.log("fetchedBank", fetchedBank);
            // }}
            title={t("Add_Bank") +  t("Account")}
            okButtonProps={{
              type: "primary",
              style: { background: "#4096ff" },
            }}
          >
            <div
              className="flex flex-wrap justify-start"
              style={{ gap: "8px" }}
            >
              {banks?.map((bank, index) => (
                <div
                  className={`bank-item rounded-md border-solid border-2 ${
                    fetchedBank?.id === bank.id
                      ? "border-blue-400"
                      : " border-gray-200 "
                  }`}
                  key={index}
                  style={{
                    flexBasis: "23%",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    // setValues({ ...values, merchant_bank_account: bank.id });
                    setFetchedBank({ id: bank.id, name: bank.name });
                  }}
                >
                  <img
                    src={bank.icon}
                    style={{
                      width: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </div>
              ))}
            </div>
            <div className="bank_acc mt-2 d-flex align-items-center justify-content-end">
            <button className="cancel_btn mr-2" onClick={() => {
              showSelectBank(false);
            }}>{t("Cancel")}</button>
            <button style={{background:"rgb(64,150,255)", color:"white"}} onClick={() => {
              showSelectBank(false);
              setSelectedBank(fetchedBank.id);
              setSelectedBankName(fetchedBank.name);
            }}>{t("OK")}</button>
            </div>
          </TransitionsModal>
          <div
            className="select_bank flex justify-between align-center"
            onClick={() => showSelectBank(true)}
          >
            <div className="bank_placeholder">{selectedBankName}</div>
            <span>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          </div>

          <h4>{t("Account_Number")}</h4>
          <Input
            onChange={handleAccountNumber}
            value={accNumber}
            style={{ marginTop: "6px", marginBottom: "1rem" }}
            placeholder={t("Account_Number")}
          />
          <div className="bank_acc mt-2 d-flex align-items-center justify-content-end">
            <button className="cancel_btn mr-2" onClick={() => {
          showBankModal(false);
          setSelectedBank("");
        }}>{t("Cancel")}</button>
            <button style={{background:"rgb(64,150,255)", color:"white"}} onClick={handleAddBank}>{t("OK")}</button>
            </div>
        </div>
      </TransitionsModal>
      <Drawer
        title={t("My_Bank_Accounts")}
        placement={"bottom"}
        closable={true}
        onClose={() => setBankOpen(false)}
        open={bankOpen}
        height={500}
        headerStyle={{ background: "white" }}
        bodyStyle={{ borderRadius: 0 }}
      >
        <div>
          <Button
            onClick={() => showBankModal(true)}
            style={{ width: "100%", margin: "1rem 0", height: "40px" }}
          >
            {t("Add_Bank")} {t("Account")}
          </Button>
          <BankList setFullName={setFullName} />
        </div>
      </Drawer>
      {isBankLoading && <Loading />}
    </div>
  );
}
