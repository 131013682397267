import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Disclosure } from "@headlessui/react";
import { Divider, Empty } from "antd";
import userService from "../service/user.service";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";

const BetHistory = () => {
  // const [betHistory, setBetHistory] = useState([
  //   {
  //     betid: "1zxcfd9sd",
  //     detail: {
  //       GameName: "TestGame",
  //       Type: "Slot",
  //       AppId: "asd2dsd",
  //       StartBalance: 50,
  //       CurrencyCode: "USD",
  //       EndBalance: 20,
  //       Description: "This is a test game",
  //     },
  //     payout: 2,
  //     turnover: 23,
  //     bet: 50,
  //     winlose: 20,
  //     matchtime: "12/06/2024",
  //   },
  //   {
  //     betid: "1zxcfd9sd",
  //     detail: {
  //       GameName: "TestGame",
  //       Type: "Slot",
  //       AppId: "asd2dsd",
  //       StartBalance: 50,
  //       CurrencyCode: "USD",
  //       EndBalance: 20,
  //       Description: "This is a test game",
  //     },
  //     payout: 2,
  //     turnover: 23,
  //     bet: 50,
  //     winlose: 20,
  //     matchtime: "12/06/2024",
  //   },
  //   {
  //     betid: "1zxcfd9sd",
  //     detail: {
  //       GameName: "TestGame",
  //       Type: "Slot",
  //       AppId: "asd2dsd",
  //       StartBalance: 50,
  //       CurrencyCode: "USD",
  //       EndBalance: 20,
  //       Description: "This is a test game",
  //     },
  //     payout: 2,
  //     turnover: 23,
  //     bet: 50,
  //     winlose: 20,
  //     matchtime: "12/06/2024",
  //   },
  //   {
  //     betid: "1zxcfd9sd",
  //     detail: {
  //       GameName: "TestGame",
  //       Type: "Slot",
  //       AppId: "asd2dsd",
  //       StartBalance: 50,
  //       CurrencyCode: "USD",
  //       EndBalance: 20,
  //       Description: "This is a test game",
  //     },
  //     payout: 2,
  //     turnover: 23,
  //     bet: 50,
  //     winlose: 20,
  //     matchtime: "12/06/2024",
  //   },
  // ]);
  
  const [betHistory, setBetHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();

  const getBetHistory = async () => {
    try {
      setLoading(true);
      const wallet = await userService.getBalance();
      const userId = wallet.data.user.id;

      const betHistory = await userService.getBetHistory(userId);
      console.log(betHistory);

      //Todo: Remove once the api is implemented
      setBetHistory(betHistory.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBetHistory();
  }, []);

  return (
    <div className="py-0">
      {loading && <Loading />}
      <div className={`list-group list-custom list-group-m rounded-xs`}>
        {betHistory && betHistory.length > 0 ? (
          <>
            {betHistory.map((item) => {
              return (
                <Disclosure as="div" className="mt-2 bg-slate-50">
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className="flex w-full justify-between items-center rounded-lg 
                    bg-gray-100 px-4 py-2 text-left text-sm font-medium text-sky-700 hover:bg-slate-200 focus:outline-none focus-visible:ring focus-visible:bg-slate-200 focus-visible:ring-opacity-75"
                      >
                        <div>
                          <span className="text-base flex items-end gap-2">
                            {item?.detail?.GameName}
                            <span className="text-stone-900 text-sm">
                              {t("Bet")} {item.bet}
                            </span>
                            <span
                              className={`${
                                Number(item.winlose) < 0
                                  ? "text-rose-700"
                                  : "text-lime-700"
                              } text-sm`}
                            >
                              {Number(item.winlose) < 0 ? "Lose" : "Win"}{" "}
                              {item.winlose}
                            </span>
                          </span>
                          <span className="font-[400] text-stone-500">
                            {new Date(item.matchtime).toLocaleString()}
                          </span>
                        </div>
                        <Icon
                          icon="mingcute:down-line"
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-sky-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                        <div className="flex gap-2 text-sm font-semibold">
                          <span className="text-lime-500">
                            {item.detail.Type}
                          </span>
                          <span className="text-purple-500">
                            ID: {item.betid}
                          </span>
                          <span className="text-pink-500">
                            {t("AppID")}: {item.detail.AppID}
                          </span>
                        </div>
                        <Divider className="my-2 text-stone-500" />
                        <div className="text-sm">
                          <div>
                            {t("Start_Balance")}:{" "}
                            <span className="text-base font-semibold text-stone-900">
                              {item.detail.StartBalance}
                            </span>{" "}
                            <span className="text-sm">
                              {item.detail.CurrencyCode}
                            </span>
                          </div>
                          <div>
                            {t("End_Balance")}:{" "}
                            <span className="text-base font-semibold text-stone-900">
                              {item.detail.EndBalance}
                            </span>{" "}
                            <span className="text-sm">
                              {item.detail.CurrencyCode}
                            </span>
                          </div>
                          <div>
                            {t("Payout")}:{" "}
                            <span className="text-base font-semibold text-stone-900">
                              {item.payout}
                            </span>{" "}
                            <span className="text-sm">
                              {item.detail.CurrencyCode}
                            </span>
                          </div>
                          <div>
                            {t("Turnover")}:{" "}
                            <span className="text-base font-semibold text-stone-900">
                              {item.turnover}
                            </span>{" "}
                            <span className="text-sm">
                              {item.detail.CurrencyCode}
                            </span>
                          </div>
                        </div>
                        <Divider className="my-2" />
                        {item.detail.Description}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              );
            })}
          </>
        ) : (
          <Empty style={{marginTop: '2rem'}} description={`${t("No_items_found")}!`} />
        )}
      </div>
    </div>
  );
};

export default BetHistory;
