import "./Transfers.css";
import React from "react";
import formatDate from "../../utils/formatDate";

const TransferList = ({ orders }) => {
  return (
    <div className="transfer-container">
      {orders.map((item, idx) => (
        <div key={(item.id + idx) * Math.random()} className="transfer-item">
          <div className="left">
            <img
              className="avatar"
              size="large"
              src={item.product.image_mobile}
              alt="thumb"
            />
            <div>
              {item.state === "error" ? (
                <h4>Error</h4>
              ) : (
                <h4>{item.ttype === "TD" ? "Stop Game" : "Start Game"}</h4>
              )}
              <p>
                {formatDate(item.created_at).formattedDate}{" "}
                {formatDate(item.created_at).time}
              </p>
            </div>
          </div>
          <div className="right">
            <h5>{item.product.creadit_type === "CA" ? "Cash" : "Chips"}</h5>
            <p>
              MYR{" "}
              <span className={`${item.amount > 0 ? "green" : "red"}`}>
                {item.amount}
              </span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TransferList;
