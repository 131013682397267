import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import AuthService from "../service/auth.service";
import UserService from "../service/user.service";
import { useTranslation } from "react-i18next";
import Loading from './Loading';


export default function BalanceSection({
  hideQr,
  getWalletInfo,
  walletInfo,
  startedGameList,
  StopProduct,
  setStartedGameList
}) {
  const [currentUser, setCurrentUser] = useState(null);
  // const [count, setCount] = useState(0);
  // const [refreshing, setRefreshing] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();
  const {t} = useTranslation();

  // const handleRefresh = () => {
  //   setRefreshing(true); // Start the refresh animation
  //   setTimeout(() => {
  //     setRefreshing(false); // Stop the refresh animation after 1 second (adjust as needed)
  //   }, 1000);
  //   navigate("/"); // Add your refresh logic here
  // };

  // const getNotificationInfo = async () => {
  //   const getNotification = await UserService.getNotificationCount();
    // setCount(getNotification?.data?.count);
  // };

  

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      // getWalletInfo();
      // getNotificationInfo();
    }
  }, [localStorage.getItem("user")]);

  return (
    <div
      className="balance-container p-2 d-flex justify-between mb-2"
      style={{
        border: "1px solid rgb(92, 149, 224)",
        background: "linear-gradient(to bottom, #5D9CEC, #4A89DC) !important"

      }}
    >
      {hideQr ? (
        ""
      ) : (
        <div className="w-1/5">
          <Icon
            style={{color:"black"}}
            icon="grommet-icons:qr"
            fontSize={36}
            className="mr-10 cursor-pointer qr-icon"
            onClick={() => navigate("/referral")}
          />
        </div>
      )}
      <div className="d-flex items-center justify-center gap-2 balance-account w-2/5">
      {/* <span className="icon icon-lg cash-custom-icon"></span> */}
        <span className="" style={{color:"black",fontWeight:"bold"}}>MYR</span>
        <div
          className={`d-flex flex-col items-end text-stone-900`}
          style={{ fontWeight: "bold" }}
        >
          <h6>{t("Cash")}</h6>
          <h6>{currentUser ? walletInfo?.balance : "0.00"}</h6>
        </div>
      </div>
      <div className="d-flex items-center justify-center gap-2 balance-account w-2/5">
        {/* <span className="icon icon-lg chips-custom-icon"></span> */}
        <span className="" style={{color:"black",fontWeight:"bold"}}>MYR</span>
        <div
          className={`d-flex flex-col items-end text-stone-900`}
          style={{ fontWeight: "bold" }}
        >
          <h6>{t("Chips")}</h6>
          <h6>{currentUser ? walletInfo?.chips_balance : "0.00"}</h6>
        </div>
      </div>
      <div className="w-1/5">
          <Icon
            style={{color:"black"}}
            icon="ic:outline-refresh"
            fontSize={36}
            className={`mr-10 cursor-pointer qr-icon`}
            onClick={async (e) => {
              setLoading(true);
              startedGameList.forEach((startedGame) => {
                StopProduct(startedGame.id);
              });
              setTimeout(() => {
                setLoading(false);
              }, 500);
            }}
          />
        </div>
        {isLoading && <Loading />}
    </div>
  );
}
