import React from "react";
import { Tabs } from "antd";
import "./component.scss";
import { useTranslation } from "react-i18next";


const Tablist = ({ tablist, setTabNum, resetSlots }) => {
  const {t} = useTranslation();

  const categories = [
    {
      id: 1,
      key: "news",
      name: "news",
      label: (
        <div className="category-game">
          <img className="category-image" 
          src={require("../images/news.png")} 
          alt={"news"} />
          <div>{t("News")}</div>
        </div> 
      ),
    },
    {
      id: 2,
      key: "",
      name: "All",
      label: (
        <div className="category-game">
          <img className="category-image" 
          src={require("../images/all.png")} 
          alt={"All"} />
          <div>{t("All")}</div>
        </div>
      ),
    },
    {
      id: 3,
      key: "casino",
      name: "Live",
      label: (
        <div className="category-game">
          <img
            className="category-image"
            src={require("../images/casino.png")}
            alt={"Live"}
          />
          <div>{t("Live")}</div>
        </div>
      ),
    },
    {
      id: 4,
      key: "slots",
      name: "slots",
      label: (
        <div onClick={() => resetSlots()} className="category-game"> 
          <img
            className="category-image"
            src={require("../images/slot.png")}
            alt={"slots"}
          />
          <div>{t("Slots")}</div>
        </div>
      ),
    },
    {
      id: 5,
      key: "sportsbook",
      name: "sportsbook",
      label: (
        <div className="category-game"> 
          <img
            className="category-image"
            src={require("../images/sportbook.png")}
            alt={"sportsbook"}
          />
          <div>{t("Sports")}</div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="home-tab-container">
        <Tabs
          //   tabBarStyle={{ marginLeft: "8px" }}
          // style={{padding: '0 8px'}}
          onChange={(val) => {
            const data = categories.filter((category) => category.key == val)[0]
              ?.children;
            console.log("val", val);
            setTabNum(val);
          }}
          items={categories}
        />
      </div>
    </>
  );
};

export default Tablist;
