import React from "react";
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/reset.css';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeContextProvider } from "./contexts/ThemeContext";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { initializeI18next } from "./i18n";
import { LanguageContextProvider } from "./i18n/LanguageContext";

initializeI18next();

ReactDOM.render(
  <React.StrictMode>
    <ThemeContextProvider>
    <LanguageContextProvider>
      <App />
    </LanguageContextProvider>
    </ThemeContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorkerRegistration.register()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
