import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TacImg from "../../../images/Two factor authentication-rafiki.png";
import { toast } from "react-toastify";
import authService from "../../../service/auth.service";
import OTPInput from "react-otp-input";
import { Input } from "antd";

const VerifyTac = () => {
  const [code, setCode] = useState();
  const [newPass, setNewPass] = useState();

  const navigate = useNavigate();
  const { state } = useLocation();

  const VerifyCode = async () => {
    if (code) {
      if (code.length < 5) {
        return toast.error("OTP cannot be less than 5 digit!");
      }

      if (!newPass) {
        return toast.error("Please enter your new password!");
      }

      if (newPass.length < 6) {
        return toast.error(
          "Password length should be equal or more than 6 characters!"
        );
      }

      try {
        const mobile = state.mobile;
        const password = newPass;
        const tac = code;
        const values = {
          mobile,
          password,
          tac,
        };
        const result = await authService.resetPassword(values);

        if (result.status === 500 || result.status === 400) {
          toast.error("Something went wrong! Please try again");
        }

        toast.success(`Password changed successfully!`);
        navigate("/signin");
      } catch (err) {
        console.log(err)
        if (err.response) {
          const data = err.response.data;
          if (data.password) {
            toast.error(data.password[0]);
          } else if (data.not_found) {
            toast.error("TAC or mobile is invalid!");
          }
        } else {
          toast.error("Something went wrong! Please try again");
        }
      }
    } else {
      toast.error("OTP cannot be empty!");
    }
  };

  return (
    <div
      style={{ paddingBottom: "5rem", paddingTop: "1.5rem" }}
      className="flex flex-col items-start justify-between h-screen w-full"
    >
      <div>
        <ArrowLeftOutlined
          onClick={() => navigate("../forget-password")}
          style={{ fontSize: "1.3rem", color: "#000" }}
        />
      </div>
      <div className="flex flex-col h-full">
        <img src={TacImg} alt="forget-password" style={{height:"300px",width:"300px",alignSelf:"center"}} />
        <div>
          <p className="text-3xl mb-2 font-bold text-black">Change Password</p>
          <p className="font-medium mb-8 w-2/3">
            Enter 5 digit code that has been sent to +{state.cc}
            {state.mobile} and change the password.
          </p>
          <div className="w-full">
            <OTPInput
              containerStyle={{
                marginTop: "1.5rem",
                columnGap: "10px",
                justifyContent: "space-between",
              }}
              inputStyle={{
                width: "50px",
                height: "50px",
                borderRadius: "8px",
                color: "black",
                fontSize: "2rem",
                fontWeight: "bold",
              }}
              value={code}
              onChange={setCode}
              numInputs={5}
              separator={<span>-</span>}
              renderInput={(props) => (
                <input {...props} pattern="[0-9]*" type="number" />
              )}
              shouldAutoFocus
            />
            <Input.Password
              style={{ height: "50px", marginTop: "1.5rem", fontWeight: "500" }}
              placeholder="Enter new password"
              onChange={e => setNewPass(e.target.value)}
            />
            <button
              className="zIndex1 w-100 btn btn-full 
          gradient-highlight shadow-bg shadow-bg-s mt-8"
              style={{
                height: "50px",
                borderRadius: "15px",
                fontWeight: "bold",
                color: "rgb(49, 140, 214)",
              }}
              onClick={VerifyCode}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyTac;
