import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const Slider = ({ images }) => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <div className="">
            <div className="slider-wrapper" style={{justifycontent: "space-between"}}>
                {
                    (images?.length > 0) &&
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        responsive={responsive}
                        infinite={true}
                        removeArrowOnDeviceType={["tablet", "mobile", 'desktop']}
                        showDots={false}
                        ssr={true}
                        shouldResetAutoplay={false}
                        autoPlaySpeed={5000}
                        autoPlay={true}
                    >
                        {images.map((item, index) => (
                            <img src={item.imgSrc} alt="banner" key={index} draggable={false} style={{ borderRadius: 15 }} />
                        ))}
                    </Carousel>
                }
            </div>
        </div>
    );
};

export default Slider;
