import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import authService from "../../../service/auth.service";
import tokenService from "../../../service/token.service";
import Loading from "../../../components/Loading";
import { useTranslation } from "react-i18next";

const Verify = ({ userId }) => {
    const [code, setCode] = useState();
    const [verifyVal, setVerifyVal] = useState('');
    const [waitTime, setWaitTime] = useState(0);
    const [mobileNum, setMobileNum] = useState('');
    const [newUserID, setNewUserID] = useState();
    const [isGetting, setIsGetting] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate();
    const {t} = useTranslation();

    const getTacCode = async (userId) => {
        setIsGetting(true)
        try {
            const TacCode = await authService.getTac(userId);
            setMobileNum(TacCode?.data?.recipient);
            if(!TacCode?.data.wait) {
                setWaitTime(60);
            }
            return TacCode?.data?.tac;
        } catch (err) {
            console.log(err)
            if (err.response.data.wait) {
                setWaitTime(err.response.data.wait)
            }
        }
        setIsGetting(true)
    }

    const VerifyCode = async () => {
        setLoading(true);
        if (code) {
            try {
                await authService.verifyTac({ userId, code });
                navigate('/save');
            } catch (err) {
                if (err?.response?.data?.tac) {
                    const username_msg = err.response.data.tac;
                    if (Number(String(username_msg).slice(-2)) < 0) {
                        navigate('/signup');
                    }
                    setVerifyVal(username_msg);
                }
                if (err?.response?.data?.detail) {
                    const username_msg = err.response.data.detail;
                    setVerifyVal(username_msg);
                }
            }
        }
        setLoading(false);
    }

    const reSendCode = async () => {
        if (newUserID && waitTime === 0) {
            await getTacCode(newUserID);
            setWaitTime(60);
        }
    }

    useEffect(() => {
        const newId = tokenService.getRegisterUserId();
        if (newId) { setNewUserID(Number(newId)) }
        if ((userId || newId) && !isGetting) {
            getTacCode(Number(userId || newId));
        }
    }, [userId, newUserID, localStorage.getItem('registerUser')])

    useEffect(() => {
        if (waitTime > 0) {
            setTimeout(() => {
                setWaitTime(value => value - 1)
            }, 1000)
        }
    }, [waitTime])

    return (
        <div className="">
            <div className="pt-200">
                <div className="text-sm text-center">{t("TAC_is_sent_to")} <span className="text-sky-600">{mobileNum}</span></div>
                <OtpInput
                    containerStyle={{ marginTop: "1.5rem", columnGap: "10px", justifyContent: "space-between" }}
                    inputStyle={{ width: "50px", height: "50px", borderRadius: "8px", color: "black", fontSize: "2rem", fontWeight: "bold" }}
                    value={code}
                    onChange={setCode}
                    numInputs={5}
                    separator={<span>-</span>}
                    renderInput={(props) => <input {...props} pattern="[0-9]*" type="number" />}
                    shouldAutoFocus
                />
                <div className={`text-center mt-2 ${(verifyVal === '') ? 'valid-feedback' : 'invalid-feedback'} `}>{verifyVal}</div>
                {waitTime > 0 && <div className="flex items-center justify-center text-sm mt-3">
                    <div>0{Math.floor(waitTime / 60)}:</div>
                    <div>{(waitTime < 10 ? '0' : '') + waitTime % 60}</div>
                </div>}
                <label className="pt-10 form-check-label font-12 resend-wrap" htmlFor="c2a">
                    {t("Did_Not_get_code")} <span className={`resend-code ${waitTime > 1 ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`} onClick={reSendCode}>{t("Resend_Code")}</span>.
                </label>
                <button className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-4" onClick={VerifyCode}>
                    {t("Verify")}
                </button>
            </div>
            {
                isLoading && <Loading />
            }
        </div>
    );
};

export default Verify;
