import { useState, useEffect } from "react";
import { Modal, Radio, Space } from "antd"
import userService from "../service/user.service";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";
import TransitionsModal from "./common/TransitionsModal";

const amountlist = [
    { id: 1, value: '50' },
    { id: 2, value: '100' },
    { id: 3, value: '200' },
    { id: 4, value: '300' },
    { id: 5, value: '500' },
];


const EWallet = ({ ewalletid, getMerchantEWalletslist, item }) => {

    const initialValues = {
        ttype: "EW",
        amount: "",
        merchant_bank_account: 0,
    };


    const [values, setValues] = useState(initialValues);
    const [radioValue, setRadioValue] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [promotionList, setPromotionList] = useState([]);
    const [selectedTnC, setSelectedTnC] = useState({
        label: '',
        value: 0
    });
    const [loading, setLoading] = useState(false);

    const [validations, setValidations] = useState({
        amount: "",
        non_field_errors: ""
    })

    const {
        amount: amountVal,
        non_field_errors: nonVal,
    } = validations
    const {t} = useTranslation();

    const onChangeRadio = (e) => {
        setRadioValue(e.target.value);
    };

    const handleNumChange = (e) => {
        const RE = /^\d*\.?\d{0,18}$/;
        if (RE.test(e.target.value) || e.target.value === '') {
            setValues(values => {
                return { ...values, [e.target.name]: e.target.value };
            });
        }
    }

    const handleDeposit = async e => {
        e.preventDefault();

        if (values.amount < 30) {
            let min_amt_msg;
            min_amt_msg = "min deposit amount is 30.";
            setValidations({
                ...validations, amount: min_amt_msg
            });
            return;
        }

        setLoading(true);

        let response;
        try {
            response = await userService.createDeposit(values);

            const url = response?.data?.action?.url;
            const bankdata = response?.data?.action?.data;

            let form_data = new FormData();

            Object.entries(bankdata).forEach(([key, value]) => {
                form_data.append(`${key}`, value);
            })

            try {

                var form = document.createElement("form");

                form.method = "POST";
                form.action = url;

                let element = [{}];
                Object.entries(bankdata).forEach(([key, value], index) => {
                    element[index] = document.createElement("input");
                    element[index].name = key;
                    element[index].value = value;
                    form.appendChild(element[index]);
                })

                document.body.appendChild(form);
                form.style = "display: none";

                form.submit();

                document.body.removeChild(form);

            } catch (error) {
                console.log("error ==> ", error);
            }

        } catch (err) {
            console.log("err => ", err, values)
            let amount_msg;
            let non_msg;

            if (err?.response?.data?.amount) {
                amount_msg = err.response.data.amount;
            }
            if (err?.response?.data?.non_field_errors) {
                non_msg = err.response.data.non_field_errors;
            }
            setValidations({
                ...validations, amount: amount_msg, non_field_errors: non_msg
            });
        }
        setLoading(false);
    }

    const getPromotions = async () => {
        setLoading(true);
        const promotionListing = await userService.getPromotions();

        const nonObj = {
            id: 0,
            title: t("No_promotion"),
            active: true
        }

        promotionListing.data.push(nonObj);
        promotionListing.data.sort((a, b) => a.id - b.id);

        setPromotionList(promotionListing.data);
        setSelectedTnC(promotionListing.data[0]);
        setRadioValue(promotionListing.data[0].id);
        setLoading(false);
    }

    useEffect(() => {
        getMerchantEWalletslist();
        getPromotions();
    }, [])

    useEffect(() => {
        if (ewalletid && item) {
            const bank_acc = item?.bank;
            setValues(values => {
                return { ...values, merchant_bank_account: ewalletid };
            });
        }
    }, [ewalletid, item])

    return (
        <div className="reload-wrap">
            <div className="d-flex justify-center">
                <img src={item?.icon} alt="" className="icon icon-lg" />
            </div>
            <div className="form-custom form-label mt-3">
                <input
                    name="amount"
                    type="number"
                    className="form-control rounded-xs"
                    id="c1"
                    placeholder={t("Amount")}
                    onChange={handleNumChange}
                    value={values.amount}
                />
                <div className={`${amountVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{amountVal}</div>
            </div>

            <div className="d-flex text-center justify-content-between tab-scroll-wrap">
                {amountlist.map((item, index) => (
                    <div className="w-fit pr-10 zIndex0" key={index}>
                        <div
                            className={`icon icon-m shadow-bg shadow-bg-s rounded-xs ${values.amount === item.value ? 'border color-highlight' : 'bg-highlight'}`}
                            onClick={() => setValues({ ...values, amount: item.value })}
                        >
                            {item.value}
                        </div>
                    </div>
                ))}
            </div>

            <Radio.Group onChange={onChangeRadio} value={radioValue} className="my-4">
                <Space direction="vertical">
                    {(promotionList && promotionList.length > 0) && promotionList.filter(item => item.active).map((item, index) => {
                        return (
                            <Radio value={item.id} key={index} onChange={() => setSelectedTnC(item)} onClick={() => {
                                setModalOpen(true)
                                if (item.id !== 0) {
                                    setValues(values => {
                                        return { ...values, promotions_applied: item.id }
                                    })
                                } else {
                                    delete values.promotions_applied;
                                }
                            }}>{item.title}</Radio>
                        )
                    })}
                </Space>
            </Radio.Group>

            <div className={`${nonVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nonVal}</div>

            <button
                className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-3 h-40"
                onClick={handleDeposit}
            >
                {t("Submit")}
            </button>

            <TransitionsModal
                title={selectedTnC.title}
                centered
                open={modalOpen}
                footer={[]}
                onClose={() => setModalOpen(false)}
            >
                <div className="text-lg font-semibold">{t("Terms&Conditions")}</div>

                <div className="mt-2" dangerouslySetInnerHTML={{ __html: selectedTnC.description }} />
            </TransitionsModal>
            {loading && <Loading />}
        </div>
    );
};

export default EWallet;
