import { useState, useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import AuthService from "./service/auth.service";
import MainLayout from "./MainLayout";
import AuthLayout from "./AuthLayout";
import NoInternet from "./NoInternet";
import { countries } from "./i18n/languages";
import { LanguageContext } from "./i18n/LanguageContext";

const COUNTRY = process.env.REACT_APP_COUNTRY;
const LANG = process.env.REACT_APP_LANG;


function App() {
  const [isAuth, setAuth] = useState(true);
  const [isOnline, setIsOnline] = useState(true);
  const { language, setLanguage } = useContext(LanguageContext)

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setAuth(true);
    } else {
      setAuth(false);
    }
  }, [localStorage.getItem("user")]);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    const country = countries[COUNTRY]
      if (country) {
        setLanguage({
          lang: LANG,
          img: country.flag,
          country: COUNTRY
        })
      }
    // Cleanup event listeners
    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (isOnline) {
      setIsOnline(navigator.onLine);
    }
  }, [isOnline]);

  return (
    <div className={`app-container light-bg`}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          {isOnline ? (
            <Route
              path="/*"
              element={
                isAuth ? (
                  <MainLayout setAuth={setAuth} />
                ) : (
                  <AuthLayout setAuth={setAuth} />
                )
              }
            />
          ) : (
            <Route path="/*" element={<NoInternet />} />
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
