import api, { PublicAxios } from './api';

class UserService {
  getNoticesImages() {
    return PublicAxios.get('/notices/?active');
  }
  
  getNewsfeeds() {
    return api.get('/newsfeeds/');
  }

  getSliderImages() {
    return PublicAxios.get('/promotions/');
  }

  getTablist() {
    return PublicAxios.get('/products/categories/');
  }

  getProducts(category) {
    return PublicAxios.get(`/products/?category=${category}`);
  }

  getEztechProducts(start, end) {
    return PublicAxios.get(`/product/eztech88/games/?_start=${start}&_end=${end}`)
  }

  getJokerProducts(start, end) {
    return PublicAxios.get(`/product/joker/games/?_start=${start}&_end=${end}`)
  }

  getHabaneroProducts(start, end) {
    return PublicAxios.get(`/product/habanero/games/?_start=${start}&_end=${end}`)
  }

  getPlaytechProducts(start, end) {
    return PublicAxios.get(`/product/playtech/games/?_start=${start}&_end=${end}`)
  }

  getJDBProducts(start, end) {
    return PublicAxios.get(`/product/jdb/games/?_start=${start}&_end=${end}`)
  }

  getSpadeGamingProducts(start, end) {
    return PublicAxios.get(`/product/spadegaming/games/?_start=${start}&_end=${end}`)
  }

  getLive22Products(start, end) {
    return PublicAxios.get(`/product/live22/games/?_start=${start}&_end=${end}`)
  }

  getPragmaticProducts(start, end) {
    return PublicAxios.get(`/product/pragmatic/games/?_start=${start}&_end=${end}`)
  }

  getVpowerProducts(start, end) {
    return PublicAxios.get(`/product/vpower/games/?_start=${start}&_end=${end}`)
  }

  getJiliProducts(start, end) {
    return PublicAxios.get(`/product/jili/games/?_start=${start}&_end=${end}`)
  }


  getStartedGames(id) {
    return api.get(`/user-game-account/${id}`)
  }

  getBalance() {
    return api.get('/wallet/');
  }

  getNotificationCount() {
    return api.get('/notifications/count');
  }

  getPromotions() {
    return PublicAxios.get('/promotions/');
  }

  getBanks() {
    return PublicAxios.get('/banks/?type=B');
  }

  getBankDetails(bankId) {
    return PublicAxios.get(`/banks/${bankId}`)
  }

  getMerchantBanks() {
    return PublicAxios.get('/merchant-bank-accounts/');
  }

  getMerchantEWallets() {
    return PublicAxios.get('/merchant-ewallet-accounts/');
  }

  getCustomBanks() {
    return api.get('/customer-bank-accounts/');
  }

  addCustomerBank(values) {
    return api.post('/customer-bank-accounts/', values)
  }

  getCouponTypelist() {
    return api.get('/coupon-types/');
  }

  createDeposit(values) {
    return api.post('/deposits/', values);
  }

  withdraw(values) {
    return api.post('/withdrawals/', values);
  }

  startProduct(id, selectedCredit) {
    return api.post(`/product/${id}/start/?credit_type=${selectedCredit}`);
  }

  stopProduct(id) {
    return api.post(`/product/${id}/stop/`);
  }

  startEasytechProduct(id, selectedCredit) {
    return api.post(`/product/eztech88/start/${id}/?credit_type=${selectedCredit}`);
  }

  startEasytogoProduct(id, gameid, selectedCredit) {
    return api.post(`/product/easytogo/start/${id}/${gameid}/?credit_type=${selectedCredit}`);
  }

  checkProduct(id) {
    return api.get(`/product/${id}/game-account/`)
  }

  getOrders({_start, _end}, filters) {
    if(filters){
    return api.get(`/orders/filter?_start=${_start}&_end=${_end}&date=${filters.date}&ttype=${filters.ttype}`)
    }
    return api.get(`/orders/?_start=${_start}&_end=${_end}`)
  }

  getChips({_start, _end}) {
    return api.get(`/chips/?_start=${_start}&_end=${_end}`)
  }

  getTransfers({_start, _end}) {
    return api.get(`/transfers/?_start=${_start}&_end=${_end}`)
  }

  getBonues({_start, _end}) {
    return api.get(`/bonuses/?_start=${_start}&_end=${_end}`)
  }

  deleteActor(id) {
    return api.delete(`/actors/transactions/${id}`)
  }

  deleteChipTx(id) {
    return api.delete(`/chips/${id}`)
  }

  getAppAccounts() {
    return api.get(`/game-accounts/?ltype=app`)
  }

  getBetHistory(userId) {
    return api.get(`/user-bet-history`, userId)
  }

  getRewards() {
    return api.get(`/multi-level-rewards/`)
  }

  getSession() {
    return api.get('/session/')
  }
}

export default new UserService();
