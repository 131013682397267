import { useEffect, useState } from "react";
import userService from "../service/user.service";
import Loading from "../components/Loading";
import { useTranslation } from "react-i18next";

const Account = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false)
  const [myBank, setBank] = useState({
    name: "",
    icon: "",
    number: "",
    metaData: {
      name: ""
    }
  });

  const fetchWallet = async () => {
    setLoading(true)
    try {

      // fetch customer bank details
      const customerBanks = await userService.getCustomBanks();
      const customerBank = customerBanks.data;
      if (customerBank.length === 0) {
        return;
      }
      const bank = customerBank[0];
      setBank({
        ...bank,
        metaData: {
          name: ""
        }
      });

      // fetch bank meta data
      const bankData = await userService.getBankDetails(bank.bank);
      if (bankData.status === 200) {
        const bankObj = {
          ...bank,
          metaData: bankData.data
        }
        setBank(bankObj);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false)
  };

  useEffect(() => {
    fetchWallet();
  }, [localStorage.getItem("user")]);

  return (
    <div className="py-24">
      {loading && <Loading />}
      <form className="demo-animation needs-validation" noValidate>
        <br />
        <div className="text-left ml-2">
          {t("Username")} :
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="fa fa-person-circle font-14"></i>
          <input
            type="text"
            className="form-control rounded-xs"
            id="c1"
            placeholder="Full Name"
            pattern="[A-Za-z ]{1,32}"
            readOnly
            required
            value={myBank.name}
          />
        </div>
        <div className="text-left ml-2">
          {t("Bank")} :
        </div>
        <div className="bank-account-img form-control rounded-xs form-label form-icon mb-3">
          <input
            type="text"
            placeholder="Bank"
            readOnly
            required
            value={myBank.metaData.name}
          />
          <img src={myBank?.icon} alt="" className="w-10 h-10" />
        </div>
        <div className="text-left ml-2">
          {t("Bank")} {t("Account_Number")} :
        </div>
        <div className="form-custom form-label form-icon mb-3">
          <i className="fa fa-telephone-fill font-12"></i>
          <input
            type="tel"
            className="form-control rounded-xs"
            id="c3"
            placeholder="Bank Account"
            pattern="[+ 0-9]{10,15}"
            readOnly
            required
            value={myBank.number}
          />
        </div>
        <div className="text-center">
          Remarks: For security purposes, please contact us to modify any of
          information above
        </div>
      </form>
    </div>
  );
};

export default Account;
