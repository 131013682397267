import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ForgetImg from "../../../images/Feb-Business_9.jpg";
import { toast } from "react-toastify";
import authService from "../../../service/auth.service";


const initialValues = {
  cc: "60",
  mobile: "",
};

const Forget = () => {
  const [values, setValues] = useState(initialValues);

  const navigate = useNavigate();

  const handleSendTac = async () => {
    try {
        const result = await authService.getForgetTac(values)

        const mobile = result.data.recipient;
        toast.success(`TAC sent to your mobile +${mobile}`, { theme: "light" });
        navigate('/verify-tac', {state: values})
    }catch(err) {
        toast.error(`Failed to send Tac. Please try again later!`, {theme: 'light'})
        console.log(err)
    }
  };

  return (
    <div
      style={{ paddingBottom: "5rem", paddingTop: "1.5rem" }}
      className="flex flex-col items-start justify-between h-screen w-full"
    >
      
      <div style={{fontSize:"25px",fontWeight:"bold",color:"black",display:"inline-block"}}>
        <ArrowLeftOutlined
          onClick={() => navigate("../")}
          style={{ fontSize: "1.3rem", color: "#000" }}
        />  Forgot Password 
      </div>
      <div className="flex flex-col justify-between h-full mt-3 ">
        <div>
          {/* <p className="text-3xl mb-2 font-bold text-black">Forget Password?</p> */}
          <p className="font-medium mb-3" style={{color:"black"}}>
          Please Provide Correct Mobile Number To Reset New Password .           
          </p>
          <div className="w-full">
            <PhoneInput
              inputStyle={{
                width: "100%",
                borderRadius: "8px",
                height: "50px",
                background: "transparent",
              }}
              containerStyle={{ marginTop: "16px" }}
              buttonStyle={{ borderRadius: "12px",background: "transparent",color:"transparent",border:"none"}}
              country={"my"}
              disableDropdown
              onChange={(phone, country) => {
                const reducedPhone = phone.replace(country.dialCode, "");
                setValues({
                  ...values,
                  cc: country.dialCode,
                  mobile: reducedPhone,
                });
              }}
              inputProps={{
                placeholder: "Enter Mobile Number",
              }}
            />
            <button
              className="zIndex1 w-100 btn btn-full 
          gradient-highlight shadow-bg shadow-bg-s mt-8"
              style={{
                height: "50px",
                borderRadius: "15px",
                fontWeight: "bold",
                color: "rgb(49, 140, 214)",
              }}
              onClick={handleSendTac}
            >
              RESET PASSWORD
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forget;
