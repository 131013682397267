import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  Button,
  Select,
  Input,
} from "antd";
import { Icon } from "@iconify/react";
import {
  MenuOutlined,
} from "@ant-design/icons";
import "../pages/pages.scss";
import QRCode from "qrcode";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import QRCodeSection from "../pages/QRCodeSection";
import BankList from "../components/BankList";
import authService from "../service/auth.service";
import userService from "../service/user.service";
import Rebate from "../pages/Rebate";
import BetHistory from "../pages/BetHistory";
import { useTranslation } from "react-i18next";
import TransitionsModal from "./common/TransitionsModal";
import { countries } from "../i18n/languages";
import { useContext } from "react";
import { LanguageContext } from "../i18n/LanguageContext";
import { Typography } from "@mui/material";

const initialValue = {
  old_password: "",
  password: "",
  password2: "",
};

const { Option } = Select;

const countryObj = {
  "my": require("../i18n/flags/my-flag.png"),
  "sg": require("../i18n/flags/sg-flag.png")
}

export default function Header({
  getWalletInfo,
  walletInfo,
  setCurrentUserGlobal,
  setAuth,
}) {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [isBankLoading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [userId, setUserId] = useState();
  const [pwChangeModal, setPwChangeModal] = useState(false);
  const [values, setValues] = useState(initialValue);
  const [selectBank, showSelectBank] = useState(false);
  const [fetchedBank, setFetchedBank] = useState("");
  const { language, setLanguage } = useContext(LanguageContext)
  const [languageModal, setLanguageModal] = useState(null);

  const [validations, setValidations] = useState({
    old_password: "",
    password: "",
    password2: "",
    refreshVal: "",
  });
  const [successChangePassword, setSuccessChangePassword] = useState(false);
  const [deleteTxn, setDeleteTxn] = useState(false);
  const [dateJonied, setDateJoined] = useState("");
  const [orders, setOrders] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [bonuses, setBonuses] = useState([]);
  const [referCode, setReferCode] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [bankOpen, setBankOpen] = useState(false);
  const [bankModal, showBankModal] = useState(false);
  const [banks, setBanks] = useState([]);
  const [selectedBank, setSelectedBank] = useState("");
  const [selectedBankName, setSelectedBankName] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [accNumber, setAccNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(15);
  const [pageDone, setPageDone] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [rebateDrawer, setRebateDrawer] = useState(false);
  const [betDrawer, setBetDrawer] = useState(false);

  const handWithdraw = (url) => {
    navigate(url);
  };

  const getBankList = async () => {
    setIsLoading(true);
    const getBanks = await userService.getBanks();
    setBanks(getBanks?.data);
    setIsLoading(false);
  };

  const {
    old_password: currentPW,
    password: newPW,
    password2: newPWConfirm,
    refreshVal: refreshVal,
  } = validations;

  const getUserInfo = async () => {
    try {
      const userInfo = await userService.getBalance();
      const referralLink = userInfo.data.referral_link;
      const joinedTime = new Date(userInfo.data.user.date_joined);
      const year = joinedTime.getFullYear();
      const month = (joinedTime.getMonth() + 1).toString().padStart(2, "0");
      const day = joinedTime.getDate().toString().padStart(2, "0");
      const todayDate = `${year}-${month}-${day}`;
      setDateJoined(todayDate);
      setUserId(userInfo.data.user.id);
      return referralLink;
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogout = async () => {
    try {
      await authService.logout();
      setCurrentUserGlobal(null);
      setAuth(false);
      navigate("/signin");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  const generateQRCode = async (referral) => {
    try {
      await QRCode.toDataURL(referral);
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangeInputValue = (e) => {
    setValues((values) => {
      return { ...values, [e.target.name]: e.target.value };
    });
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    try {
      await authService.changePassword({ values, userId });
      setSuccessChangePassword(true);
      setPwChangeModal(false);
      Swal.fire({
        icon: "success",
        text: "The password modification has been completed successfully.",
      });
    } catch (err) {
      console.log(err.response.data);
      setValidations({
        ...validations,
        old_password: err.response.data?.old_password?.old_password,
        password: err.response.data?.password,
        password2: err.response.data?.password2,
        refreshVal: err.response.data?.detail,
      });
    }
  };

  const handleDelete = async (txnId, type) => {
    try {
      setIsLoading(true);
      await userService.deleteActor(userId, txnId);

      if (type === "order") {
        const newItems = [...orders];
        const filterItems = newItems.map((item) => ({
          ...item,
          state: item.id === txnId ? "deleted" : item.state,
        }));
        setOrders(filterItems);
      }
      if (type === "transfer") {
        const newItems = [...transfers];
        const filterItems = newItems.map((item) => ({
          ...item,
          state: item.id === txnId ? "deleted" : item.state,
        }));
        setTransfers(filterItems);
      }
      if (type === "bonus") {
        const newItems = [...bonuses];
        const filterItems = newItems.map((item) => ({
          ...item,
          state: item.id === txnId ? "deleted" : item.state,
        }));
        setBonuses(filterItems);
      }

      setDeleteTxn(!deleteTxn);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "failed",
        text: err,
      });
    }
  };

  const handleAddBank = async () => {
    try {
      if (selectedBank === "") return toast.error(t("Please_Select") + t("Bank"));
      if (accNumber === "") return toast.error(("Please_Enter") + ("Account_Number"));
      setLoading(true);

      const wallet = walletInfo.id;
      const user = walletInfo.user.id;

      const values = {
        wallet,
        user,
        number: accNumber,
        name: fullName,
        bank: selectedBank,
      };
      console.log(values);
      const result = await userService.addCustomerBank(values);
      if (!result.data.id) {
        setLoading(false);
        showBankModal(false);
        setBankOpen(false);
        return toast.error("Bank account with selected bank already exist!");
      }

      toast.success(t("Bank") + t("added successfully") + "!");
    } catch (err) {
      console.log(err);
      if (err.response) {
        const data = err.response.data;
        if (data.type) {
          return toast.error(`Type field is required!`);
        } else if (data.icon) {
          return toast.error(data.icon[0]);
        }
        return toast.error("Something went wrong! Please try again");
      }
    }
    setLoading(false);
    showBankModal(false);
    setBankOpen(false);
  };

  const handleAccountNumber = (e) => {
    const RE = /[0-9\b]$/;
    if (RE.test(e.target.value) || e.target.value === "") {
      setAccNumber(e.target.value);
    }
  };

  const handleClick = () => {
    setLanguageModal(true);
  };

  useEffect(() => {
    const initialSetting = async () => {
      try {
        setIsLoading(true);
        const referral = await getUserInfo();
        await generateQRCode(referral);

        const url = new URL(referral);
        const code = url.searchParams.get("referrer");
        setReferCode(code);

        const user = authService.getCurrentUser();
        if (userId) {
          setIsLoading(true);
        }
        if (user) {
          setCurrentUser(user);
          getWalletInfo();
        }
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    initialSetting();
  }, [localStorage.getItem("user"), userId]);

  const fetchTransactions = async (start, end) => {
    setFetching(true);
    try {
      const orderData = await userService.getOrders({
        _start: start,
        _end: end,
      });
      const txnData = await userService.getTransfers({
        _start: start,
        _end: end,
      });
      const bonusData = await userService.getBonues({
        _start: start,
        _end: end,
      });

      if (orderData.data) {
        setOrders((prevOrders) => [...prevOrders, ...orderData.data]);
      }
      if (txnData.data) {
        setTransfers((prevTxns) => [...prevTxns, ...txnData.data]);
      }

      if (bonusData.data) {
        setBonuses((prevBonuses) => [...prevBonuses, ...bonusData.data]);
      }
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => {
    getBankList();
  }, []);

  useEffect(() => {
    if (!pageDone) {
      fetchTransactions(startPage, endPage);
    }
  }, [startPage, endPage, pageDone]);

  useEffect(() => {
    const baseLimit = 15;
    function handleScroll() {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight) {
        // Bottom reached, trigger your action here
        setStartPage((prevStart) => prevStart + baseLimit);
        setEndPage((prevEnd) => prevEnd + baseLimit);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // Declare the currentUser variable here or receive it from props

  return (
    <div className="header-container">
      <Button
        type="text"
        className="hamburger-btn"
        style={{ color: "black" }}
        icon={<MenuOutlined />}
        onClick={() => setShowDrawer(true)}
      />
      <Drawer
        placement="left"
        closable={false}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        className="header-drawer"
        style={{ width: "70%" }}
      >
        {/* Sidebar content */}
        <div className="sidebar-content">
          <div
            className="flex flex-col items-center cursor-pointer p-2"
            style={{ flexDirection: "inherit" }}
            onClick={() =>
              currentUser ? setBankOpen(true) : navigate("../../signin")
            }
          >
            <div className="bg-gradient-to-r from-violet-500 to-purple-500 w-max p-2 rounded-lg">
              <Icon
                icon="icon-park-outline:bank-card"
                fontSize={30}
                className="text-white"
              />
            </div>
            <div className="text-sm font-semibold text-slate-900 text-center ml-3">
              {t("Bank")} {t("Account")}
            </div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer p-2"
            style={{ flexDirection: "inherit" }}
            onClick={() =>
              currentUser ? setPwChangeModal(true) : navigate("../../signin")
            }
          >
            <div className="bg-gradient-to-r from-cyan-500 to-blue-500 w-max p-2 rounded-lg">
              <Icon
                icon="fluent:password-24-regular"
                fontSize={30}
                className="text-white"
              />
            </div>
            <div className="text-sm font-semibold text-slate-900 text-center ml-3">
              {t("Change_Password")}
            </div>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer p-2"
            style={{ flexDirection: "inherit" }}
          >
            <a
              id="nav-homes"
              data-submenu="sub1"
              className={`list-group-item flex items-center flex-col `}
              style={{ border: "0px", flexDirection: "inherit" }}
              onClick={() => {
                currentUser
                  ? handWithdraw("/app-accounts")
                  : handWithdraw("/signin");
              }}
            >
              <div className="bg-red-dark from-cyan-500 to-blue-500 w-max p-2 rounded-lg">
                <Icon icon="ion:qr-code" fontSize={30} className="text-white" />
                {/* <i className="fa bg-blue-dark shadow-bg shadow-bg-xs bi-house-fill">
                <i className="-ml-15 fa fa-qrcode color-white"></i>
              </i> */}
              </div>
              <div className="text-sm font-semibold text-slate-900 text-center ml-3">
                {t("Game_Account")}
              </div>
            </a>
          </div>

          <div
            className="flex flex-col items-center cursor-pointer p-2"
            style={{ flexDirection: "inherit" }}
          >
            <a
              id="nav-homes"
              data-submenu="sub1"
              className={`list-group-item flex items-center flex-col `}
              style={{ border: "0px", flexDirection: "inherit" }}
              onClick={() => {
                currentUser ? setRebateDrawer(true) : handWithdraw("/signin");
                setShowDrawer(false);
              }}
            >
              <div className="bg-green-dark from-cyan-500 to-blue-500 w-max p-2 rounded-lg">
                <Icon
                  icon="ph:money-fill"
                  fontSize={30}
                  className="text-white"
                />
              </div>
              <div className="text-sm font-semibold text-slate-900 text-center ml-3">
                {t("Rebate")}
              </div>
            </a>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer p-2 "
            style={{ flexDirection: "inherit" }}
          >
            <a
              id="nav-homes"
              data-submenu="sub1"
              className={`list-group-item flex items-center flex-col `}
              style={{ border: "0px", flexDirection: "inherit" }}
              onClick={() => {
                currentUser ? setBetDrawer(true) : handWithdraw("/signin");
                setShowDrawer(false);
              }}
            >
              <div className="bg-brown-dark from-cyan-500 to-blue-500 w-max p-2 rounded-lg">
                <Icon
                  icon="material-symbols:history"
                  fontSize={30}
                  className="text-white"
                />
              </div>
              <div className="text-sm font-semibold text-slate-900 text-center ml-3">
                {t("Bet_History")}
              </div>
            </a>
          </div>
          <div
            className="flex flex-col items-center cursor-pointer p-2"
            style={{ flexDirection: "inherit" }}
          >
            <a
              id="nav-homes"
              data-submenu="sub1"
              className={`list-group-item flex items-center flex-col `}
              style={{ border: "0px", flexDirection: "inherit" }}
              onClick={() => {
                currentUser
                  ? handWithdraw("/referral")
                  : handWithdraw("/signin");
              }}
            >
              <div className="bg-yellow-dark from-cyan-500 to-blue-500 w-max p-2 rounded-lg">
                <Icon icon="ion:qr-code" fontSize={30} className="text-white" />
              </div>
              <div className="text-sm font-semibold text-slate-900 text-center ml-3">
                {t("QRCode")}
              </div>
            </a>
          </div>
        </div>
        {currentUser ? (
          <button
            type="submit"
            className="w-full btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"
            onClick={() => {
              authService.logout();
              setCurrentUserGlobal(null);
              setAuth(false);
              window.location.reload();
              navigate("/signin");
            }}
          >
            {t("Log_out")}
          </button>
        ) : (
          <button
            type="submit"
            className="w-full btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"
            onClick={() => {
              navigate("/signin");
            }}
          >
            {t("Login")}
          </button>
        )}
      </Drawer>
      <div role="button" onClick={handleClick}>
        <img
          className="logo-container"
          src={language && language.img ? language.img : countryObj["my"]}
          alt={"Language"}
        />
      </div>
      <TransitionsModal
        title={"Select Language"}
        className="antd-promotion-modal"
        footer={[]}
        open={languageModal}
        onClose={() => { setLanguageModal(false)}}
        style={{ textAlign: "center", height: "100%" }}
        containerStyle={{ height: "100%" }}
      >
        <div>
          {
            Object.keys(countries).map(country => (
              <Typography className="d-flex align-item-center mb-2" onClick={() => {
                // setLanguage({
                //   lang: language.key,
                //   img: language.img
                // })
              }} sx={{ py: 1, px: 1, fontSize: 20, borderBottom: "1px solid #ccc" }}>
                <img style={{ width: "30px", marginRight: "10px" }} src={countries[country].flag} alt="" />

                {
                  countries[country].supported_languages.map((supported_language, index) => {
                    return <>
                            <div role="button" onClick={() => {
                              setLanguage({
                                lang: supported_language.lang,
                                img: countries[country].flag,
                                country
                              })
                              setLanguageModal(false)
                            }} 
                            style={{ 
                              color: language && (language.country == country) && (language.lang === supported_language.lang) ? 
                                    "rgb(49, 140, 214)": 
                                    "black" }}>{supported_language.label}
                            </div>{index<(countries[country].supported_languages.length-1) ? " | ": ""}
                          </>
                  })
                }
              </Typography>
            ))
          }
        </div>
      </TransitionsModal>

      <TransitionsModal
        centered
        open={pwChangeModal}
        onClose={() => {
          setPwChangeModal(false);
        }}
        footer={[]}
      >
        <form onSubmit={handleChangePassword}>
          <div className="login-modal-title">{t("Change_Password")}</div>
          <br />
          <div className="form-custom form-label form-border form-icon bg-transparent">
            <div>
              <i className="fa fa-asterisk font-13"></i>
              <input
                name="old_password"
                type="password"
                className="form-control rounded-xs"
                id="c1"
                placeholder="Current password"
                onChange={handleChangeInputValue}
              />
              <label htmlFor="c1" className="color-theme">
                {t("Username")}
              </label>
              <span>({t("Required")})</span>
            </div>
            <div
              className={`${
                currentPW === "" ? "valid-feedback" : "invalid-feedback"
              } `}
            >
              {currentPW}
            </div>
            <div
              className={`${
                refreshVal === "" ? "valid-feedback" : "invalid-feedback"
              } `}
            >
              {refreshVal}
            </div>
          </div>
          <div className="form-custom form-label form-border form-icon bg-transparent">
            <div>
              <i className="fa fa-asterisk font-13"></i>
              <input
                name="password"
                type="password"
                className="form-control rounded-xs"
                id="c2"
                placeholder="New password"
                onChange={handleChangeInputValue}
              />
              <label htmlFor="c2" className="color-theme">
                {t("Password")}
              </label>
              <span>({t("Required")})</span>
            </div>
            <div
              className={`${
                newPW === "" ? "valid-feedback" : "invalid-feedback"
              } `}
            >
              {newPW}
            </div>
          </div>
          <div className="form-custom form-label form-border form-icon mb-4 bg-transparent">
            <div>
              <i className="fa fa-asterisk font-13"></i>
              <input
                name="password2"
                type="password"
                className="form-control rounded-xs"
                id="c2"
                placeholder="Confirm your new password"
                onChange={handleChangeInputValue}
              />
              <label htmlFor="c2" className="color-theme">
                {t("Password")}
              </label>
              <span>({t("Required")})</span>
            </div>
            <div
              className={`${
                newPWConfirm === "" ? "valid-feedback" : "invalid-feedback"
              } `}
            >
              {newPWConfirm}
            </div>
          </div>
          <button
            type="submit"
            className="w-100 btn btn-full gradient-highlight shadow-bg shadow-bg-s"
          >
            {t("Change_Password")}
          </button>
        </form>
      </TransitionsModal>

      <Drawer
        title="Referrel Link"
        placement={"bottom"}
        closable={true}
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        height={600}
        headerStyle={{ background: "white" }}
        bodyStyle={{ borderRadius: 0 }}
      >
        <div>
          <QRCodeSection />
        </div>
      </Drawer>

      <TransitionsModal
        open={bankModal}
        onClose={() => {
          showBankModal(false);
          setSelectedBank("");
        }}
        onOk={handleAddBank}
        title="Add Bank Account"
        okButtonProps={{ type: "primary", style: { background: "#4096ff" } }}
      >
        <div>
          <h4>{t("Full_Name")}</h4>
          <Input
            style={{ marginBottom: "1rem", marginTop: "6px" }}
            placeholder="Full Name"
            readOnly
            value={fullName}
          />
          <h4>{t("Select_Bank")}</h4>
          {/* <Select
            style={{ width: "100%", marginTop: "6px", marginBottom: "1rem" }}
            onChange={(e) => setSelectedBank(e)}
            placeholder="Select a Bank"
            value={selectedBank}
          >
            {banks.map((b, index) => (
              <Option value={b.id} key={index}>
                <div className="flex flex-row gap-2">
                  <img src={b.icon} className="w-12 h-8 rounded-sm" />
                  <p>{b.name}</p>
                </div>
              </Option>
            ))}
          </Select> */}
          <TransitionsModal
            open={selectBank}
            onClose={() => {
              showSelectBank(false);
              // setSelectedBank("");
            }}
            onOk={() => {
              showSelectBank(false);
              setSelectedBank(fetchedBank.id);
              setSelectedBankName(fetchedBank.name);
              console.log("fetchedBank", fetchedBank);
            }}
            title={`${t("Add_Bank")} ${t("Account")}`}
            okButtonProps={{
              type: "primary",
              style: { background: "#4096ff" },
            }}
          >
            <div
              className="flex flex-wrap justify-start"
              style={{ gap: "8px" }}
            >
              {banks?.map((bank, index) => (
                <div
                  className={`bank-item rounded-md border-solid border-2 ${
                    fetchedBank?.id === bank.id
                      ? "border-blue-400"
                      : " border-gray-200 "
                  }`}
                  key={index}
                  style={{
                    flexBasis: "23%",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    // setValues({ ...values, merchant_bank_account: bank.id });
                    setFetchedBank({ id: bank.id, name: bank.name });
                  }}
                >
                  <img
                    src={bank.icon}
                    style={{
                      width: "100%",
                      objectFit: "cover",
                    }}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </TransitionsModal>
          <div
            className="select_bank flex justify-between align-center"
            onClick={() => showSelectBank(true)}
          >
            <div className="bank_placeholder">{selectedBankName}</div>
            <span>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          </div>

          <h4>{t("Account_Number")}</h4>
          <Input
            onChange={handleAccountNumber}
            value={accNumber}
            style={{ marginTop: "6px", marginBottom: "1rem" }}
            placeholder="Account Number"
          />
        </div>
      </TransitionsModal>
      <Drawer
        title={t("My_Bank_Accounts")}
        placement={"bottom"}
        closable={true}
        onClose={() => setBankOpen(false)}
        open={bankOpen}
        height={500}
        headerStyle={{ background: "white" }}
        bodyStyle={{ borderRadius: 0 }}
      >
        <div>
          <Button
            onClick={() => showBankModal(true)}
            style={{ width: "100%", margin: "1rem 0", height: "40px" }}
          >
            {t("Add_Bank")} {t("Account")}
          </Button>
          <BankList setFullName={setFullName} />
        </div>
      </Drawer>

      <Drawer
        title={t("Rebate") + " " +  t("History")}
        placement={"left"}
        closable={true}
        onClose={() => setRebateDrawer(false)}
        open={rebateDrawer}
        headerStyle={{ background: "white" }}
        bodyStyle={{ borderRadius: 0 }}
      >
        <Rebate />
      </Drawer>
      <Drawer
        title={t("Bet_History")}
        placement={"left"}
        closable={true}
        onClose={() => setBetDrawer(false)}
        open={betDrawer}
        headerStyle={{ background: "white" }}
        bodyStyle={{ borderRadius: 0 }}
      >
        <BetHistory />
      </Drawer>
    </div>
  );
}

// export default Header;
