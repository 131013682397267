import { useState, useEffect } from "react";
import { Upload, message, Button, Radio, Space } from "antd";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import UserService from "../service/user.service";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";
import TransitionsModal from "./common/TransitionsModal";

const amountlist = [
  { id: 1, value: "50" },
  { id: 2, value: "100" },
  { id: 3, value: "200" },
  { id: 4, value: "300" },
  { id: 5, value: "500" },
];

const initialValues = {
  ttype: "IB",
  amount: "",
  proof: undefined,
  merchant_bank_account: "",
};

const init_validation = {
  amount: "",
  merchant_bank_account: "",
  non_field_errors: "",
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG/JPEG file!");
  }
  return isJpgOrPng;
};

const Manual = () => {
  const [values, setValues] = useState(initialValues);
  const [banks, setBanks] = useState([]);
  const [radioValue, setRadioValue] = useState(null);
  const [merchantBanks, setMerchantBanks] = useState([]);
  const [selectedBank, setSelectedBan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [validations, setValidations] = useState(init_validation);
  const [isLoading, setAPILoading] = useState(false);
  const [txnId, setTxnId] = useState(false);
  const [isTxnModal, setIsTxnModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [promotionList, setPromotionList] = useState([]);
  const [selectedTnC, setSelectedTnC] = useState({
    title: "",
    description: "",
  });

  const navigate = useNavigate();
  const {t} = useTranslation();

  const {
    amount: amountVal,
    merchant_bank_account: bank_Val,
    non_field_errors: nonVal,
  } = validations;

  const onChangeRadio = (e) => {
    setRadioValue(e.target.value);
  };

  const getBankList = async () => {
    setAPILoading(true);
    const getBanks = await UserService.getBanks();
    setBanks(getBanks?.data);
    setAPILoading(false);
  };

  const getMerchantBanklist = async () => {
    setAPILoading(true);
    const getMerchantlist = await UserService.getMerchantBanks();
    setMerchantBanks(getMerchantlist?.data);
    setAPILoading(false);
  };

  const success = (number) => {
    navigator.clipboard.writeText(number).then(() => {
      messageApi.open({
        type: "success",
        content: "Bank Account Number Copied",
      });
    });
  };

  const handleNumChange = (e) => {
    const RE = /^\d*\.?\d{0,18}$/;
    if (RE.test(e.target.value) || e.target.value === "") {
      setValues((values) => {
        return { ...values, [e.target.name]: e.target.value };
      });
    }
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        const proofData = {};
        proofData.title = info.file.name;
        proofData.base64 = url;

        setValues({ ...values, proof: proofData });
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        {t("Upload")}
      </div>
    </div>
  );

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const getPromotions = async () => {
    const promotionListing = await UserService.getPromotions();

    const nonObj = {
      id: 0,
      title: "No promotion",
      active: true
    }

    promotionListing.data.push(nonObj);
    promotionListing.data.sort((a, b) => a.id - b.id);

    setPromotionList(promotionListing.data);
    setSelectedTnC(promotionListing.data[0]);
    setRadioValue(promotionListing.data[0].id);
  };

  const handleDeposit = async (e) => {
    e.preventDefault();
    setAPILoading(true);

    let response;
    try {
      response = await UserService.createDeposit(values);
      setTxnId(response.data.id);
      setIsTxnModal(true);
      setTimeout(() => {
        navigate("/account");
      }, 5000);
      setImageUrl();
      setValues(initialValues);
      setValidations(init_validation);
    } catch (err) {
      console.log("err => ", err);
      let non_msg;
      let amount_msg;
      let bank_msg;

      if (err?.response?.data?.amount) {
        amount_msg = err.response.data.amount;
      }
      if (err?.response?.data?.merchant_bank_account) {
        bank_msg = err.response.data.merchant_bank_account;
      }
      if (err?.response?.data?.non_field_errors) {
        non_msg = err.response.data.non_field_errors;
      }
      setValidations({
        ...validations,
        amount: amount_msg,
        merchant_bank_account: bank_msg,
        non_field_errors: non_msg,
      });
    }
    setAPILoading(false);
  };

  useEffect(() => {
    getPromotions();
    getBankList();
    getMerchantBanklist();
  }, []);

  return (
    <div className="online-wrap">
      {contextHolder}
      <h2 className="my-10 font-bold">{t("Manual_Transfer")}</h2>
      <div className="banks-container">
        <div className="banks-wrap">
          {merchantBanks?.map((bank, index) => (
            <div
              className={`col-4 bank-item ${selectedBank?.id === bank.id ? "selected-bank" : ""
                }`}
              key={index}
              onClick={() => {
                setValues({ ...values, merchant_bank_account: bank.id });
                setSelectedBan(bank);
              }}
            >
              <img
                src={
                  banks.find((bk) => bk.id === bank.bank)?.icon
                    ? banks.find((bk) => bk.id === bank.bank)?.icon
                    : "/images/game01.png"
                }
                width={50}
                alt=""
              />
              <div style={{ fontSize: "12px" }}>{bank.name}</div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={`${bank_Val === "" ? "valid-feedback" : "invalid-feedback"
          } `}
      >
        {bank_Val}
      </div>
      {selectedBank && (
        <div className="banks-info">
          <table>
          <tbody>
              <tr>
              <td>{t("Bank")} {t("Account")} {t("Name")}</td>
              <td>{selectedBank?.name}</td>
            </tr>
            <tr>
              <td>{t("Bank")} {t("Account_Number")}</td>
              <td>{selectedBank?.number}</td>
              <td
                className="copy-btn text-uppercase"
                onClick={() => {
                  navigator.clipboard.writeText(selectedBank?.number)
                  success(selectedBank?.number)
                }}
                >
                {t("Copy")}
              </td>
            </tr>
          </tbody>
          </table>
        </div>
      )}

      <div className="form-custom form-label mt-3">
        <input
          name="amount"
          type="number"
          className="form-control rounded-xs"
          id="c3"
          placeholder={t("Amount")}
          onChange={handleNumChange}
          value={values.amount}
        />
        <div
          className={`${amountVal === "" ? "valid-feedback" : "invalid-feedback"
            } `}
        >
          {amountVal}
        </div>
      </div>

      <div className="d-flex text-center justify-content-between tab-scroll-wrap">
        {amountlist.map((item) => (
          <div className="pr-10 zIndex0" key={item.id}>
            <div
              className={`icon icon-m shadow-bg shadow-bg-s rounded-xs ${values.amount === item.value
                ? "border color-highlight"
                : "bg-highlight"
                }`}
              onClick={() => setValues({ ...values, amount: item.value })}
            >
              {item.value}
            </div>
          </div>
        ))}
      </div>

      <div style={{ width: "100%", marginTop: "1rem" }}>
        <Upload
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {imageUrl ? (
            <img
              src={imageUrl}
              alt="avatar"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </div>

      <Radio.Group onChange={onChangeRadio} value={radioValue} className="my-4">
        <Space direction="vertical">
          {promotionList &&
            promotionList.length > 0 &&
            promotionList
              .filter((item) => item.active)
              .map((item) => {
                return (
                  <Radio
                    value={item.id}
                    key={item.id}
                    onChange={() => setSelectedTnC(item)}
                    onClick={() => {
                      setModalOpen(true);
                      if (item.id !== 0) {
                        setValues((values) => {
                          return { ...values, promotion: item.id };
                        })
                      } else {
                        delete values.promotion;
                      }

                    }}
                  >
                    {item.title}
                  </Radio>
                );
              })}
        </Space>
      </Radio.Group>

      <div
        className={`${nonVal === "" ? "valid-feedback" : "invalid-feedback"} `}
      >
        {nonVal}
      </div>

      <button
        className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-3 h-40"
        onClick={handleDeposit}
      >
        {t("Submit")}
      </button>
      <TransitionsModal
        title="Congratulations!"
        style={{ top: "50px" }}
        open={isTxnModal}
        onClose={() => setIsTxnModal(false)}
        footer={[]}
      >
        <div className="text-lg font-semibold">{t("Deposit_is_successful")}.</div>
        <div className="font-semibold">
        {t("Transaction_Id")}: <span className="text-lime-700">{txnId}</span>
        </div>
        <div className="w-full flex justify-end gap-3 -mb-3">
          <Button onClick={() => setIsTxnModal(false)}>{t("Cancel")}</Button>
          <Button
            className="bg-sky-600 text-white"
            onClick={() => navigate("/account")}
          >
            {t("OK")}
          </Button>
        </div>
      </TransitionsModal>

      <TransitionsModal
        title={selectedTnC.title}
        centered
        open={modalOpen}
        footer={[]}
        onClose={() => setModalOpen(false)}
      >
        <div className="text-lg font-semibold">{t("Terms&Conditions")}</div>

        <div
          className="mt-2"
          dangerouslySetInnerHTML={{ __html: selectedTnC.description }}
        />
      </TransitionsModal>
      {isLoading && <Loading />}
    </div>
  );
};

export default Manual;
