import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ReloadTransfer from "./pages/ReloadTransfer";
import EWalletTransfer from "./pages/EWalletTransfer";
import Withdraw from "./pages/Withdraw";
import Profile from "./pages/Profile";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import userService from "./service/user.service";
import AuthService from "./service/auth.service";
import { routes } from "./routes";
import authService from "./service/auth.service";
import TxHistory from "./pages/TxHistory";

const MainLayout = ({setAuth}) => {
  const [typelist, setTypelist] = useState([]);
  const [walletInfo, setWalletInfo] = useState(null);
  const [isRefresh, setRefresh] = useState(false);
  const [ewalletsList, setEWalletsList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  const getCouponTypes = async () => {
    const typelists = await userService.getCouponTypelist();
    let typeArr = [];
    typelists?.data?.map((type) => {
      let typedata = {
        id: "",
        name: "",
      };
      typedata.id = type[0];
      typedata.name = type[1];
      typeArr.push(typedata);
    });
    setTypelist(typeArr);
  };

  const getMerchantEWalletslist = async () => {
    const ewallets = await userService.getMerchantEWallets();
    setEWalletsList(ewallets?.data);
  };

  const getWalletInfo = async () => {
    setRefresh(true);
    try {
      const getWallet = await userService.getBalance();
      setWalletInfo(getWallet?.data);
    } catch (err) {
      console.log(err);
    }
    setRefresh(false);
  };

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      getWalletInfo();
    } 
  }, [localStorage.getItem("user")]);

  return (
    <div>
      <Routes>
        {routes.map((route) => {
          const { path, element: Component } = route;
          return <Route key={path} path={path} element={<Component />} />;
        })}
        <Route
          path="/reload/:id"
          element={
            <ReloadTransfer
              typelist={typelist}
              getCouponTypes={getCouponTypes}
            />
          }
        />
        {/* <Route
          path="/ewallet/:id"
          element={
            <EWalletTransfer
              ewalletsList={ewalletsList}
              getMerchantEWalletslist={getMerchantEWalletslist}
            />
          }
        /> */}
        <Route
          path="/withdraw"
          element={<Withdraw walletInfo={walletInfo} />}
        />
        <Route
          path="/account"
          element={
            <Profile
              getWalletInfo={getWalletInfo}
              walletInfo={walletInfo}
              setCurrentUserGlobal={setCurrentUser}
              setAuth={setAuth}
            />
          }
        />
        <Route
          path="/history"
          element={
            <TxHistory
              getWalletInfo={getWalletInfo}
              walletInfo={walletInfo}
              setCurrentUserGlobal={setCurrentUser}
              setAuth={setAuth}
            />
          }
        />
        <Route
          path="/"
          element={
            <Home
              getWalletInfo={getWalletInfo}
              walletInfo={walletInfo}
              isRefresh={isRefresh}
            />
          }
        />
      </Routes>
      <Footer />
    </div>
  );
};

export default MainLayout;
