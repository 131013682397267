import Account from "./pages/Account";
import AppAccounts from "./pages/AppAccounts";
import BetHistory from "./pages/BetHistory";
import Deposit from "./pages/Deposit";
import EWallet from "./pages/EWallet";
import ManualTransfer from "./pages/ManualTransfer";
import Notification from "./pages/Notification";
import OnlineTransfer from "./pages/OnlineTransfer";
import Promotions from "./pages/Promotions";
import QRCodeSection from "./pages/QRCodeSection";
import Rebate from "./pages/Rebate";
import Reload from "./pages/Reload";
import SignIn from "./pages/auth/SignIn";
import Forget from "./pages/auth/forget/Forget";
import VerifyTac from "./pages/auth/forget/VerifyTac";

export const routes = [
    // {
    //     path: "/signin",
    //     element: SignIn
    // },
    // {
    //     path: "/forget-password",
    //     element: Forget
    // },
    // {
    //     path: "/verify-tac",
    //     element: VerifyTac
    // }, 
    {
        path: "/deposit",
        element: Deposit
    }, 
    // {
    //     path: "/online",
    //     element: OnlineTransfer
    // }, {
    //     path: "/manual",
    //     element: ManualTransfer
    // }, 
    {
        path: "/reload",
        element: Reload
    }, 
    // {
    //     path: "/ewallets",
    //     element: EWallet
    // },
     {
        path: "/promotions",
        element: Promotions
    }, {
        path: "/bethistory",
        element: BetHistory
    }, {
        path: "/profile",
        element: Account
    }, {
        path: "/rebate",
        element: Rebate
    }, {
        path: "/notification",
        element: Notification
    }, {
        path: "/referral",
        element: QRCodeSection
    }, {
        path: "/app-accounts",
        element: AppAccounts
    },
]

export const authRoutes = [
    {
        path: "/forget-password",
        element: Forget
    },
    {
        path: "/verify-tac",
        element: VerifyTac
    }, 
]