import { useState, useEffect } from 'react';
import UserService from "../service/user.service";
import Loading from '../components/Loading';
import TransitionsModal from '../components/common/TransitionsModal';
import { useTranslation } from 'react-i18next';

const Promotions = () => {
    const [promos, setPromos] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [promoModal, setPromoModal] = useState(null);
    const {t} = useTranslation();


    const getPromos = async () => {
        try {
            setLoading(true);
            const games = await UserService.getPromotions();
            if (games.data) {
                setPromos(games.data);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        getPromos();
    }, [])

    return (
        <div className="">
            <div className="pb-20" style={{overflowY: "auto", height:"500px"}}>
                {promos.map((item, index) => (
                    <div className="promo-item w-100" key={index}>
                        <img src={item.image} alt="" />
                        <div className="promo-div">
                            <button className="btn btn-full bg-blue-dark rounded-15 text-uppercase font-700 w-100 btn-s mt-2" type="submit" onClick={() => {
                                            setPromoModal(item)}}>{t("More_Info")}</button>
                        </div>
                    </div>
                ))}

            </div>
            {
                promoModal ?
                    <TransitionsModal
                        title={promoModal ? promoModal.title : "Promotion"}
                        className= "antd-promotion-modal"
                        footer={[]}
                        open={promoModal}
                        onClose={() => setPromoModal(null)}
                        style={{ textAlign: "center"}}
                    >
                        <div className='promo_modal' style={{maxHeight: "80vh", overflowY:"auto"}}>
                            <p className="text-md font-medium mt-3">
                                {promoModal && promoModal.description ?
                                    (<div dangerouslySetInnerHTML={{ __html: promoModal.description }}></div>) : null}
                            </p>
                            <button
                                onClick={() => setPromoModal(null)}
                                className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
                                            font-700 w-100 btn-l mt-3 h-12`}>{t("OK")}
                            </button>
                        </div>
                    </TransitionsModal>
                    : null
            }
            {isLoading && <Loading />}
        </div>
    );
};

export default Promotions;
