import React from "react";
import { Icon } from "@iconify/react";

const RewardListItem = ({ item, handleDelete, type }) => {
  return (
    <div
      className={`flex items-center justify-between transition-all peer mt-2 mb-4 order-list-item`}
    >
      <div className="flex items-center gap-2 w-full">
        <div className="text-base w-full d-flex justify-content-between">
          <div>
            {/* <div className="mb-1 flex text-slate-900">
              Test:
              <span className="font-semibold">{item.txid}</span>
            </div> */}

            {/* <div className="flex justify-between w-full items-center"> */}
            <div className="flex items-center gap-1 text-slate-500 mb-1">
              <Icon icon="tabler:clock" />
              <div className="text-sm">
                {new Date(item.to_date).toLocaleString()}
              </div>
            </div>

            <div
              className={`${
                item.state === "pending"
                  ? "bg-yellow-500"
                  : item.state === "approved"
                  ? "bg-green-500"
                  : item.state === "rejected"
                  ? "bg-fuchsia-500"
                  : (item.state === "deleted" || item.state === "error") &&
                    "bg-rose-500"
              } p-0.5 w-max rounded-full text-sm px-2 text-white flex items-center gap-1`}
            >
              {item.state === "pending" ? (
                <Icon icon="ic:round-clock" fontSize={18} />
              ) : item.state === "approved" ? (
                <Icon icon="ic:round-check" fontSize={18} />
              ) : item.state === "rejected" ? (
                <Icon icon="fluent-mdl2:blocked-12" fontSize={18} />
              ) : (
                item.state === "deleted" && (
                  <Icon icon="icon-park-outline:delete-five" fontSize={18} />
                )
              )}
              <div className="first-letter:uppercase">{item.state}</div>
            </div>
          </div>

          <div>
            <div className="text-lg font-semibold text-slate-900 d-flex align-items-center justify-content-center">
              <span className="d-flex w-100 justify-content-between align-items-center">
                <span className="text-sm font-400 text-slate-500">
                  Referred :
                </span>{" "}
                &nbsp;{item.referred_player && item.referred_player.username}
              </span>
            </div>
            <div className="text-lg font-semibold text-slate-900 d-flex align-items-center">
              <span className="d-flex w-100 justify-content-between align-items-center">
                <span className="text-sm font-400 text-slate-500">
                  Actual Amt :
                </span>{" "}
                &nbsp;{item.reward_amount}
              </span>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default RewardListItem;
