import { Space, Spin } from 'antd';

const Loading = () => {
    return (
        <div className="position-fixed w-screen h-screen top-0 left-0 d-flex justify-center zIndex100 bg-01">
            <Space>
                <Spin size="large" />
            </Space>
        </div>
    );
};

export default Loading;
