import { Table, Tag } from "antd";
import { useEffect } from "react";
import userService from "../service/user.service";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Rebate = () => {
  const [rewards, setRewards] = useState([])
  const {t} = useTranslation();
  const columns = [
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      render: (text) => <a>{text}</a>,
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (_, { status }) => {
        let color = "yellow";
        if (status === "Approve") {
          color = "green";
        } else if (status === "Reject") {
          color = "red";
        }
        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
  
  ];
    // const data = [
  //   {
  //     key: "1",
  //     date: "2 Jul 2023",
  //     amount: 32,
  //     status: "Approve",
  //   },
  //   {
  //     key: "2",
  //     date: "5 Jul 2023",
  //     amount: 62,
  //     status: "Reject",
  //   },
  //   {
  //     key: "3",
  //     date: "12 Jul 2023",
  //     amount: 12,
  //     status: "Pending",
  //   },
  //   {
  //     key: "4",
  //     date: "12 Jul 2023",
  //     amount: 42,
  //     status: "Approve",
  //   },
  // const data = [
  //   {
  //     turnover_amount: 20.00,
  //     commission_level: 0,
  //     commission_percent: 5,
  //     reward_amount: 12.00,
  //     credit_type: "CA",
  //     state: "pending",
  //     is_agent: false,
  //     beneficiary_user_id: 29,
  //     chips_id: 10,
  //     transaction_id: 149,
  //     category: "casino",
  //     date: "16-08-2023"
  // },
  // {
  //     turnover_amount: 20.00,
  //     commission_level: 0,
  //     commission_percent: 5,
  //     reward_amount: 12.00,
  //     credit_type: "CA",
  //     state: "pending",
  //     is_agent: false,
  //     beneficiary_user_id: 29,
  //     chips_id: 12,
  //     transaction_id: 150,
  //     category: "slots",
  //     date: "16-08-2023"
  // },
  // {
  //     turnover_amount: 20.00,
  //     commission_level: 0,
  //     commission_percent: 5,
  //     reward_amount: 12.00,
  //     credit_type: "CA",
  //     state: "pending",
  //     is_agent: false,
  //     beneficiary_user_id: 29,
  //     chips_id: 13,
  //     transaction_id: 151,
  //     category: "slots",
  //     date: "16-08-2023"
  // },
  // {
  //     turnover_amount: 20.00,
  //     commission_level: 0,
  //     commission_percent: 5,
  //     reward_amount: 12.00,
  //     credit_type: "CA",
  //     state: "pending",
  //     is_agent: false,
  //     beneficiary_user_id: 29,
  //     chips_id: 14,
  //     transaction_id: 152,
  //     category: "casino",
  //     date: "16-08-2023"
  // }
  // ];
  // const columns = [];
  const getRewards = async () => {
    const data = await userService.getRewards()
    console.log("rewards", data)
    if(data.data && data.data.length){
      setRewards(
        data.data.map((reward) => {
          return {
            key: reward.id,
            date: reward.date,
            amount:reward.reward_amount,
            status : reward.state,
          }
        })
      )
    }
  }
  useEffect(()=> {
    getRewards()
  },[])
  return (
    <div className="">
      <div className={`mt-4 list-group list-custom list-group-m rounded-xs`}>
        <Table columns={columns} dataSource={rewards} loading={false} pagination={false}/>
      </div>
    </div>
  );
};

export default Rebate;
