import "./pages.scss";
import React, { useState, useEffect } from "react";
import { Tabs, Skeleton, DatePicker, Select, Button, Empty } from "antd";
import { SearchOutlined, LeftOutlined } from "@ant-design/icons";
import OrderItem from "../components/listItems/OrderItem";
import { useNavigate } from "react-router-dom";
import userService from "../service/user.service";
import Swal from "sweetalert2";
import Loading from "../components/Loading";
import { Icon } from "@iconify/react";
import TransferList from "../components/listItems/TransferList";
import RewardListItem from "../components/listItems/RewardListItem";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const TxHistory = () => {
  const [orders, setOrders] = useState([]);
  const [chips, setChips] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [bonuses, setBonuses] = useState([]);
  const [rewards, setRewards] = useState([])
  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(15);
  const [pageDone, setPageDone] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [deleteTxn, setDeleteTxn] = useState(false);
  const [userId, setUserId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [filterDate, setFilterDate] = useState('');
  const [filterDateValue, setFilterDateValue] = useState(null);
  const [transactiontype, setTransactionType] = useState('');
  const [scrolling, setScrolling] = useState(true);

  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleDelete = async (txnId, type) => {
    try {
      setIsLoading(true);
      if (type === "chip") {
        await userService.deleteChipTx(txnId);
      } else {
        await userService.deleteActor(txnId);
      }

      if (type === "order") {
        const newItems = [...orders];
        const filterItems = newItems.map((item) => ({
          ...item,
          state: item.id === txnId ? "deleted" : item.state,
        }));
        setOrders(filterItems);
      }
      if (type === "transfer") {
        const newItems = [...transfers];
        const filterItems = newItems.map((item) => ({
          ...item,
          state: item.id === txnId ? "deleted" : item.state,
        }));
        setTransfers(filterItems);
      }
      if (type === "bonus") {
        const newItems = [...bonuses];
        const filterItems = newItems.map((item) => ({
          ...item,
          state: item.id === txnId ? "deleted" : item.state,
        }));
        setBonuses(filterItems);
      }
      if (type === "chip") {
        const newItems = [...chips];
        const filterItems = newItems.map((item) => ({
          ...item,
          state: item.id === txnId ? "deleted" : item.state,
        }));
        setChips(filterItems);
      }

      setDeleteTxn(!deleteTxn);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: "failed",
        text: err,
      });
    }
  };

  const transactions = {
    "WD":"withdraw",
    "IB":"deposit"
  }

  const items = [
    {
      key: "1",
      label: t("Orders"),
      children: (
        <div className="order-item">
          <div
            className="flex flex-row justify-between
          items-center rounded-md mb-4 filter"
          >
            <DatePicker 
            value={filterDateValue}
            style={{ width: "30%" }}
            onChange={(date) => {
                if (date && date["$D"] && date["$M"] && date["$y"]) {
                  setFilterDate(`${date["$y"]}-${date["$M"] + 1}-${date["$D"]}`)
                  setFilterDateValue(date)
                }
              }}
            />
            <Select
              style={{ width: "30%" }}
              // defaultValue={"deposit"}
              placeholder={t("Type")}
              onChange={(ttype) => {
                if (ttype === "withdraw") {
                  setTransactionType("WD")
                }
                if (ttype === "deposit") {
                  setTransactionType("IB")
                }
              }}
              value={transactiontype?transactions[transactiontype]:null}
            >
              <Option value="deposit">{t("Deposit")}</Option>
              <Option value="withdraw">{t("Withdraw")}</Option>
            </Select>
            {/* <Select
              style={{ width: "25%" }}
              defaultValue={"CA"}
              placeholder="Credit"
            >
              <Option value="CA">Cash</Option>
              <Option value="CH">Chips</Option>
            </Select> */}
            <Icon icon="subway:undo-1" fontSize={24} color={"#4A89DC"}
            onClick={() => {
              setScrolling(false)
              setStartPage(0)
              setEndPage(15)
              setFilterDate('')
              setFilterDateValue(null)
              setTransactionType('')
            }}
            />
            <Button
              className="gradient-highlight"
              icon={<SearchOutlined />}
              onClick={() => {
                setScrolling(false)
                setStartPage(0)
                setEndPage(15)
                fetchTransactions(0,15, true)
              }}
            ></Button>
          </div>
          {orders.length > 0 ? (
            <>
              {orders.map((order, idx) => (
                <OrderItem
                  item={order}
                  key={order.id}
                  handleDelete={handleDelete}
                  type="order"
                />
              ))}
            </>
          ) : (
            <>
              <Empty description={fetching ? "Fetching items...": `${t("No_items_found")}!`} />
            </>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: t("Transfers"),
      children: (
        <div>
          <div
            className="flex flex-row justify-between
          items-center rounded-md mb-2"
          >
            <DatePicker style={{ width: "35%" }} />
            <Select
              style={{ width: "30%" }}
              defaultValue={"deposit"}
              placeholder={t("Type")}
            >
              <Option value="deposit">{t("Deposit")}</Option>
              <Option value="withdraw">{t("Withdraw")}</Option>
            </Select>
            <Button className="gradient-highlight" icon={<SearchOutlined />}>
              {t("Search")}
            </Button>
          </div>
          {transfers.length > 0 ? (
            <TransferList orders={transfers} />
          ) : (
            <>
              <Empty description={fetching ? "Fetching items...": `${t("No_items_found")}!`} />
            </>
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: t("Chips"),
      children: (
        <div>
          {chips.length > 0 ? (
            <>
              {chips.map((order) => (
                <OrderItem
                  item={order}
                  key={order.id}
                  handleDelete={handleDelete}
                  type="chip"
                />
              ))}
            </>
          ) : (
            <>
              <Empty description={fetching ? "Fetching items...": `${t("No_items_found")}!`} />
            </>
          )}
        </div>
      ),
    },
    {
      key: "5",
      label: t("Rewards"),
      children: (
        <div>
          {rewards.length > 0 ? (
            <>
              {rewards.map((order) => (
                <RewardListItem
                  item={order}
                  key={order.id}
                />
              ))}
            </>
          ) : (
            <>
              <Empty description={fetching ? "Fetching items...": `${t("No_items_found")}!`} />
            </>
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: t("Bonuses"),
      children: (
        <div>
          {bonuses.length > 0 ? (
            <>
              {bonuses.map((order) => (
                <OrderItem
                  item={order}
                  key={order.id}
                  handleDelete={handleDelete}
                  type="bonus"
                />
              ))}
            </>
          ) : (
            <>
              <Empty description={fetching ? "Fetching items...": `${t("No_items_found")}!`} />
            </>
          )}
        </div>
      ),
    },
  ];

  const fetchTransactions = async (start, end, filter) => {
    setFetching(true);
    try {
      const orderData = await userService.getOrders({
        _start: start,
        _end: end,
      },{
        date: filterDate,
        ttype: transactiontype
      });
      const txnData = await userService.getTransfers({
        _start: start,
        _end: end,
      });
      const bonusData = await userService.getBonues({
        _start: start,
        _end: end,
      });
      const chipsData = await userService.getChips({
        _start: start,
        _end: end,
      });
      const rewardData = await userService.getRewards()

      if (orderData.data) {
        setOrders((prevOrders) => filter?[...orderData.data]:[...prevOrders, ...orderData.data]);
      }
      if (txnData.data) {
        setTransfers((prevTxns) => [...prevTxns, ...txnData.data]);
      }

      if (bonusData.data) {
        setBonuses((prevBonuses) => [...prevBonuses, ...bonusData.data]);
      }
      if (chipsData.data) {
        setChips((prevChips) => [...prevChips, ...chipsData.data]);
      }
      if(rewardData.data) {
        setRewards(rewardData.data)
      }
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => {
    if (!pageDone&&scrolling) {
      fetchTransactions(startPage, endPage, false);
    }
  }, [startPage, endPage, pageDone]);

  useEffect(() => {
    if (!filterDate && !transactiontype) {
      fetchTransactions(0, 15,true)
    }
  }, [filterDate, transactiontype])

  useEffect(() => {
    const baseLimit = 15;
    function handleScroll() {
      setScrolling(true)
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;

      if (scrollTop + clientHeight >= scrollHeight) {
        // Bottom reached, trigger your action here
        setStartPage((prevStart) => prevStart + baseLimit);
        setEndPage((prevEnd) => prevEnd + baseLimit);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="rounded-lg relative pt-2 overflow-hidden tx-history-container">
      {isLoading && <Loading />}
      <div className="tx-history-header">
        <LeftOutlined onClick={() => navigate(-1)} style={{ color: "black" }} />
        <h1>All Transactions</h1>
      </div>
      <Tabs defaultActiveKey="orders" items={items} />
      <div style={{ height: "50px", marginTop: "8px" }}>
        {fetching && (
          <Skeleton
            active
            paragraph={{ rows: 2 }}
            style={{ height: "5px", margin: "2px 0" }}
          />
        )}
      </div>
    </div>
  );
};

export default TxHistory;
