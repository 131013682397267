import { useState, useEffect } from "react";
import { Modal, Button, Radio, Space } from "antd"
import { useNavigate } from "react-router-dom";
import userService from "../service/user.service";
import TransitionsModal from "./common/TransitionsModal";

const amountlist = [
    { id: 1, value: '50' },
    { id: 2, value: '100' },
    { id: 3, value: '200' },
    { id: 4, value: '300' },
    { id: 5, value: '500' },
];

const ReloadCard = ({ tyid, typelist, getCouponTypes }) => {
    const initialValues = {
        ttype: "KD",
        amount: '',
        coupon: "",
        coupon_type: `${tyid}`,
        promotions_applied: 0
    };

    const [values, setValues] = useState(initialValues);
    const [radioValue, setRadioValue] = useState(1);
    const [txnId, setTxnId] = useState(false);
    const [isTxnModal, setIsTxnModal] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [promotionList, setPromotionList] = useState([]);
    const [selectedTnC, setSelectedTnC] = useState({
        label: '',
        value: 0
    });

    const navigate = useNavigate();

    const [validations, setValidations] = useState({
        amount: "",
        coupon: "",
        non_field_errors: ""
    })

    const {
        amount: amountVal,
        coupon: couponVal,
        non_field_errors: nonVal,
    } = validations

    const onChangeRadio = (e) => {
        setRadioValue(e.target.value);
    };

    const handleNumChange = (e) => {
        const RE = /^\d*\.?\d{0,18}$/;
        if (RE.test(e.target.value) || e.target.value === '') {
            setValues(values => {
                return { ...values, [e.target.name]: e.target.value };
            });
        }
    }

    const handleDeposit = async e => {
        e.preventDefault();

        let response;
        try {
            response = await userService.createDeposit(values);
            setTxnId(response.data.id);
            setIsTxnModal(true);
            setValues(initialValues);
        } catch (err) {
            console.log("err => ", err, values)
            let amount_msg;
            let coupon_msg;
            let non_msg;

            if (err?.response?.data?.amount) {
                amount_msg = err.response.data.amount;
            }
            if (err?.response?.data?.coupon) {
                coupon_msg = err.response.data.coupon;
            }
            if (err?.response?.data?.non_field_errors) {
                non_msg = err.response.data.non_field_errors;
            }
            setValidations({
                ...validations, amount: amount_msg, coupon: coupon_msg, non_field_errors: non_msg
            });
        }
    }

    const getPromotions = async () => {
        const promotionListing = await userService.getPromotions();
        const nonObj = {
            id: 0,
            title: "No promotion",
            active: true
        }

        promotionListing.data.push(nonObj);
        promotionListing.data.sort((a, b) => a.id - b.id);
        setPromotionList(promotionListing.data);
        setSelectedTnC(promotionListing.data[0]);
        setRadioValue(promotionListing.data[0].id);
        setValues(values => {
            return { ...values, promotions_applied: promotionListing.data[0].id }
        })
    }

    useEffect(() => {
        getCouponTypes();
        getPromotions();
    }, [])

    return (
        <div className="reload-wrap">
            <h2 className="my-10">{typelist?.find((ty) => ty?.id === tyid)?.name}</h2>
            <div className="form-custom form-label">
                <input
                    name="amount"
                    type="number"
                    className="form-control rounded-xs"
                    id="c1"
                    placeholder="Amount"
                    onChange={handleNumChange}
                    value={values.amount}
                />
                <div className={`${amountVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{amountVal}</div>
            </div>

            <div className="d-flex text-center justify-content-between tab-scroll-wrap">
                {amountlist.map((item, index) => (
                    <div className="w-fit pr-10 zIndex0" key={index}>
                        <div
                            className={`icon icon-m shadow-bg shadow-bg-s rounded-xs ${values.amount === item.value ? 'border color-highlight' : 'bg-highlight'}`}
                            onClick={() => setValues({ ...values, amount: item.value })}
                        >
                            {item.value}
                        </div>
                    </div>
                ))}
            </div>

            <div className="mt-3 form-custom form-label">
                <input
                    name="coupon"
                    type="tel"
                    className="form-control rounded-xs"
                    id="c3"
                    placeholder="Number"
                    onChange={handleNumChange}
                    value={values.coupon}
                />
                {/* <div className="invalid-feedback">Phone Number is missing or is invalid.</div> */}
                <div className={`${couponVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{couponVal}</div>
                <div className={`${nonVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nonVal}</div>
            </div>

            <Radio.Group onChange={onChangeRadio} value={radioValue} className="my-4">
                <Space direction="vertical">
                    {(promotionList && promotionList.length > 0) && promotionList.filter(item => item.active).map((item, index) => {
                        return (
                            <Radio value={item.id} key={index} onChange={() => setSelectedTnC(item)} onClick={() => {
                                setModalOpen(true)
                                setValues(values => {
                                    return { ...values, promotions_applied: item.id }
                                })
                            }}>{item.title}</Radio>
                        )
                    })}
                </Space>
            </Radio.Group>

            <button
                className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s mt-3 h-40"
                onClick={handleDeposit}
            >
                Submit
            </button>
            <TransitionsModal
                title='Congratulations!'
                style={{ top: '50px' }}
                open={isTxnModal}
                onClose={() => setIsTxnModal(false)}
                footer={[]}
            >
                <div className="text-lg font-semibold">Deposit is successful.</div>
                <div className="font-semibold">Transaction Id: <span className="text-lime-700">{txnId}</span></div>
                <div className="w-full flex justify-end gap-3 -mb-3">
                    <Button onClick={() => setIsTxnModal(false)}>Cancel</Button>
                    <Button className="bg-sky-600 text-white" onClick={() => navigate('/account')}>OK</Button>
                </div>
            </TransitionsModal>

            <TransitionsModal
                title={selectedTnC.title}
                centered
                open={modalOpen}
                footer={[]}
                onClose={() => setModalOpen(false)}
            >
                <div className="text-lg font-semibold">Terms & Conditions</div>

                <div className="mt-2" dangerouslySetInnerHTML={{ __html: selectedTnC.description }} />
            </TransitionsModal>
        </div>
    );
};

export default ReloadCard;
