import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import userService from "../service/user.service";

const Reload = () => {

    const [typelist, setTypelist] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getCouponTypes();
    }, [])

    const getCouponTypes = async () => {
        setLoading(true);
        const typelists = await userService.getCouponTypelist();
        let typeArr = [];
        typelists?.data?.map((type) => {
            let typedata = {
                id: '',
                name: ''
            };
            typedata.id = type[0];
            typedata.name = type[1];
            typeArr.push(typedata);
        })
        setTypelist(typeArr);

        setLoading(false);
    }

    return (
        <div className="reload-container">
            <br />
            <br />
            <br />
            <br />
            <div className="list-group list-custom list-menu-large" style={{ backgroundColor: 'transparent', }}>
                {typelist && typelist.map((item, index) => {
                    return (
                        <a id="nav-manual" className={`list-group-item`} key={index} onClick={() => navigate(`/reload/${item.id}`)}>
                            <div className="d-flex flex-col justify-center">
                                <span className={`icon icon-lg ${item.id}-custom-icon`}></span>

                            </div>
                            <div>{String(item.name).toLocaleUpperCase()}</div>

                            <i className={`fa fa-chevron-right`}></i>
                        </a>

                    )
                })}
                {
                    isLoading && <Loading />
                }
            </div>
        </div>
    );
};

export default Reload;
