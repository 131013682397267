import { useEffect, useState } from "react";
import { Radio, Space, Modal } from 'antd';
import userService from "../service/user.service";
import Loading from "./Loading";
import { useTranslation } from "react-i18next";
import TransitionsModal from "./common/TransitionsModal";

const amountlist = [
    { id: 1, value: 50 },
    { id: 2, value: 100 },
    { id: 3, value: 200 },
    { id: 4, value: 300 },
    { id: 5, value: 500 },
];

const initialValues = {
    ttype: "PG",
    amount: '',
};

const tnCValues = [
    {
        label: 'ALL Day Promo 12% (x2)',
        value: 1
    },
    {
        label: 'NO BONUS 0%',
        value: 2
    }
]

const Online = () => {
    const [values, setValues] = useState(initialValues);
    const [radioValue, setRadioValue] = useState(1);
    const [modalOpen, setModalOpen] = useState(false);
    const [validations, setValidations] = useState({
        amount: "",
        non_field_errors: ""
    })
    const [promotionList, setPromotionList] = useState([]);
    const [selectedTnC, setSelectedTnC] = useState({
        title: '',
        description: ''
    });
    const [isLoading, setLoading] = useState(false);
    const {t} = useTranslation();

    const {
        amount: amountVal,
        non_field_errors: nonVal,
    } = validations

    const handleNumChange = (e) => {
        const RE = /^\d*\.?\d{0,18}$/;
        if (RE.test(e.target.value) || e.target.value === '') {
            setValues(values => {
                return { ...values, [e.target.name]: e.target.value };
            });
        }
    }

    const onChangeRadio = (e) => {
        setRadioValue(e.target.value);
    };

    const handleDeposit = async e => {
        e.preventDefault();

        setLoading(true);
        let response;
        try {
            response = await userService.createDeposit(values);


            const url = response?.data?.action?.url;
            const bankdata = response?.data?.action?.data;

            let form_data = new FormData();

            Object.entries(bankdata).forEach(([key, value]) => {
                form_data.append(`${key}`, value);
            })

            try {

                var form = document.createElement("form");

                form.method = "POST";
                form.action = url;

                let element = [{}];
                Object.entries(bankdata).forEach(([key, value], index) => {
                    element[index] = document.createElement("input");
                    element[index].name = key;
                    element[index].value = value;
                    form.appendChild(element[index]);
                })

                document.body.appendChild(form);
                form.style = "display: none";

                form.submit();

                document.body.removeChild(form);

            } catch (error) {
                console.log("error ==> ", error);
            }

        } catch (err) {
            console.log("err => ", err, values)
            let amount_msg;
            let non_msg;

            if (err?.response?.data?.amount) {
                amount_msg = err.response.data.amount;
            }
            if (err?.response?.data?.non_field_errors) {
                non_msg = err.response.data.non_field_errors;
            }
            setValidations({
                ...validations, amount: amount_msg, non_field_errors: non_msg
            });
        }

        setLoading(false);
    }

    const getPromotions = async () => {
        const promotionListing = await userService.getPromotions();
        const nonObj = {
            id: 0,
            title: t("No_promotion"),
            active: true
        }

        promotionListing.data.push(nonObj);
        promotionListing.data.sort((a, b) => a.id - b.id);

        setPromotionList(promotionListing.data);
        setSelectedTnC(promotionListing.data[0]);
        setRadioValue(promotionListing.data[0].id);
    }

    useEffect(() => {
        getPromotions();
    }, [])

    return (
        <div className="">
            <h2 className="my-10 font-bold">{t("Online_Transfer")}</h2>
            <div className="form-custom form-label">
                <input
                    name="amount"
                    type="text"
                    className="form-control rounded-xs"
                    id="c3"
                    placeholder={t("Amount")}
                    onChange={handleNumChange}
                    value={values.amount}
                />
                <div className={`${amountVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{amountVal}</div>
            </div>

            <div className="d-flex text-center tab-scroll-wrap justify-content-between">
                {amountlist.map((item) => (
                    <div className="pr-10" key={item.id}>
                        <div
                            className={`icon icon-m shadow-bg shadow-bg-s rounded-xs ${values.amount === item.value ? 'border color-highlight' : 'bg-highlight'}`}
                            onClick={() => setValues({ ...values, amount: item.value })}
                        >
                            {item.value}
                        </div>
                    </div>
                ))}
            </div>
            <div className={`${nonVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nonVal}</div>

            <Radio.Group onChange={onChangeRadio} value={radioValue} className="my-4">
                <Space direction="vertical">
                    {(promotionList && promotionList.length > 0) && promotionList.filter(item => item.active).map(item => {
                        return (
                            <Radio value={item.id} key={item.id} onChange={() => setSelectedTnC(item)} onClick={() => {
                                setModalOpen(true);
                                if(item.id !== 0) {
                                    setValues(values => {
                                        return { ...values, promotions_applied: item.id }
                                    })
                                } else {
                                    delete values.promotions_applied;
                                }
                            }}>{item.title}</Radio>
                        )
                    })}
                </Space>
            </Radio.Group>

            <button
                className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-100 btn-s h-40 mt-4"
                onClick={handleDeposit}
            >
                {t("Submit")}
            </button>

            <TransitionsModal
                title={selectedTnC.title}
                centered
                open={modalOpen}
                footer={[]}
                onClose={() => setModalOpen(false)}
            >
                <div className="text-lg font-semibold">{t("Terms&Conditions")}</div>

                <div className="mt-2" dangerouslySetInnerHTML={{ __html: selectedTnC.description }} />
            </TransitionsModal>
            {
                isLoading && <Loading />
            }
        </div >
    );
};

export default Online;
