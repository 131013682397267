import api, { PublicAxios } from "./api";
import TokenService from "./token.service";

class AuthService {
    login(values) {
        return api
            .post("/token/", values)
            .then((response) => {
                if (response.data.access) {
                    TokenService.setUser(response.data);
                }

                return response.data;
            });
    }

    logout() {
        return api
        .post("/token/logout/")
        .then((response) => {
            TokenService.removeUser();
            if (response.data) {
                return response.data;
            }

        });
    }

    register(values) {
        return api.post("/signup/by-referral/", values);
    }

    getCurrentUser() {
        return TokenService.getUser();
    }

    getTac(userId) {
        return api.post(`/signup/${userId}/get-tac/`);
    }

    getForgetTac(values) {
        return api.post(`/reset-password/get-tac/`, values);
    }

    resetPassword(values) {
        return api.post(`/reset-password/reset/`, values);
    }

    verifyTac({ userId, code }) {
        return api.post(`/signup/${userId}/verify-tac/`, { "tac": code });
    }

    activate({ userId, values }) {
        return api.post(`/signup/${userId}/activate/`, values);
    }

    getBanklist() {
        return PublicAxios.get(`/banks/?type=B`);
    }

    changePassword({ values, userId }) {
        return api.put(`/change-password/${userId}/`, values)
    }
}

export default new AuthService();
