import React from "react";
import './component.scss'
import { motion } from "framer-motion";
import { Skeleton } from "antd";
import { useEffect } from "react";

const colors = [
  "#003366",
  "#0055a4",
  "#0072c6",
  "#3498db",
  "#66bfff",
  "#1a5276", 
  "#2980b9",  
  "#5499c7",  
  "#cfd8dc",
  "#e1eaf3",
];

const Gallery = ({
  gamelist,
  ezSelect,
  handleProduct,
  StopProduct,
  startedGameList,
  fetching,
  page,
  provider,
}) => {
  useEffect(() => {
    if (provider !== "") {
      const title = document.querySelector(".provider-title");

      // Get each letter and apply animation with a delay and different color
      title.innerHTML = title.textContent
        .split("")
        .map((letter, index) => {
          const delay = index * 0.1; // Adjust this value for different timing
          const color = colors[index % colors.length]; // Use a color from the list
          return `<span class="letter" style="animation-delay: ${delay}s; color: ${color}">${letter}</span>`;
        })
        .join("");
    }
  }, [provider]);

  return (
    <div style={{ paddingBottom: "1rem" }} className="gallery-wrap row">
      {ezSelect ? (
        <>
          <h3 className="provider-title">{provider}</h3>
          {provider !== "" && (
            <div className="underline-box">
              <div className="left"></div>
              <div className="right"></div>
            </div>
          )}
          {gamelist.map((game) => {
            return (
              <EasytechItem
                game={game}
                StopProduct={StopProduct}
                handleProduct={handleProduct}
                key={game.gameid}
                startedGameList={startedGameList}
                fetching={fetching}
              />
            );
          })}
        </>
      ) : (
        <>
          {startedGameList &&
            gamelist.map((game) => {
              return (
                <GameItem
                  game={game}
                  StopProduct={StopProduct}
                  handleProduct={handleProduct}
                  key={game.id}
                  startedGameList={startedGameList}
                />
              );
            })}
        </>
      )}
    </div>
  );
};

export default Gallery;

const GameItem = ({ game, handleProduct, StopProduct, startedGameList }) => {
  return (
    <div
      className="col-3 p-5 cursor-pointer"
      onClick={() => {
        if (game.id) {
          if (
            startedGameList.map((item) => item.product_id).includes(game.id)
          ) {
            StopProduct(game.id);
          } else {
            handleProduct(game);
          }
        }
      }}
    >
      <div className="position-relative">
        <div className="rounded-lg img-shadow">
          <img
            className={`rounded-lg ${
              startedGameList.map((item) => item.product_id).includes(game.id)
                ? "card-active"
                : ""
            }`}
            src={
              game.image_mobile
                ? game.is_live
                  ? game.image_mobile
                  : "/images/game01.png"
                : "/images/game01.png"
            }
            alt={game.id}
            width="100%"
          />
        </div>
        {startedGameList.map((item) => item.product_id).includes(game.id) && (
          <div className="playing-badge position-absolute">Started</div>
        )}
      </div>
      {/* <button
                className={`btn btn-sm ${startedGameList.map(item => item.product_id).includes(game.id) ? 'btn-danger' : 'bg-blue-dark'} rounded-xs text-uppercase font-700 w-100 btn-s mt-1`}
            >
                {startedGameList.map(item => item.product_id).includes(game.id) ? 'End' : 'Play'}
            </button> */}
    </div>
  );
};

const EasytechItem = ({
  game,
  handleProduct,
  StopProduct,
  startedGameList,
}) => {
  return (
    <motion.div
      initial={{ scale: 0.5 }}
      animate={{ scale: 1 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      className="col-3 p-5 cursor-pointer"
      onClick={() => {
        if (game.gameid) {
          if (
            startedGameList.map((item) => item.gameid).includes(game.gameid)
          ) {
            StopProduct(game.gameid);
          } else {
            handleProduct(game);
          }
        }
      }}
    >
      <div className="position-relative">
        <div className="rounded-lg img-shadow">
          <img
            style={{
              height: "80px",
              objectFit: "cover",
            }}
            className={`rounded-lg ${
              startedGameList.map((item) => item.gameid).includes(game.gameid)
                ? "card-active"
                : ""
            }`}
            src={game.thumbnail ? game.thumbnail : "/images/game01.png"}
            alt={game.gameid}
            width="100%"
          />
        </div>
        {startedGameList.map((item) => item.gameid).includes(game.gameid) && (
          <div className="playing-badge position-absolute">Started</div>
        )}
      </div>
      {/* <button
                className={`btn btn-sm ${startedGameList.map(item => item.product_id).includes(game.id) ? 'btn-danger' : 'bg-blue-dark'} rounded-xs text-uppercase font-700 w-100 btn-s mt-1`}
            >
                {startedGameList.map(item => item.product_id).includes(game.id) ? 'End' : 'Play'}
            </button> */}
    </motion.div>
  );
};
