import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../service/auth.service";
import LiveChat from "./LiveChat";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const [currentUser, setCurrentUser] = useState(null);

  const navigate = useNavigate();
  const {t} = useTranslation();
  const handWithdraw = (url) => {
    navigate(url);
    window.location.reload(false);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }
  }, [localStorage.getItem("user")]);

  // useEffect(() => {
  //     const lcScript = document.createElement("script");
  //     lcScript.innerHTML = `
  //       window.__lc = window.__lc || {};
  //       window.__lc.license = 15561777;
  //       window.__lc.ready = function (fn) {
  //         if (window.__lc.onReady) {
  //           window.__lc.onReady(fn);
  //         } else {
  //           window.__lc.onReady = fn;
  //         }
  //       };
  //       (function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice));
  //     `;
  //     document.head.appendChild(lcScript);

    return (
        <div className="pt-15 w-100">
            <div id="footer-bar" className="footer-bar-1 footer-bar-detached">
                {
                    currentUser ?
                        <a className="col-2" onClick={() => handWithdraw("/deposit")}><i className="fa fa-credit-card"></i>
                            <span>{t("Deposit")}</span>
                        </a>
                        :
                        <a className="col-2" onClick={() => handWithdraw("/signin")}><i className="fa fa-credit-card"></i>
                            <span>{t("Deposit")}</span>
                        </a>
                }
                {currentUser ?
                    <a className="col-2" onClick={() => handWithdraw("/withdraw")}><i className="fa fa-money">
                    </i><span>{t("Withdraw")}</span>
                    </a>
                    :
                    <a className="col-2" onClick={() => handWithdraw("/signin")}><i className="fa fa-money">
                    </i><span>{t("Withdraw")}</span>
                    </a>
                }
                <a className="col-2 home-icon flex flex-col items-center" onClick={() => handWithdraw("/")}>
                    <img src={'/F8_play.png'} className="cursor-pointer" style={{ width: '50px', background: 'transparent', borderRadius: 20 }} />
                    <span>{t("Home")}</span>
                </a>
                {
                    currentUser ?
                        <a className="col-2">
                            <i className="fa fa-user" onClick={() => handWithdraw("/account")}>
                            </i>
                            <span>{t("Account")}</span>
                        </a> :
                        <a className="col-2">
                            <i className="fa fa-user" onClick={() => handWithdraw("/signin")}>
                            </i>
                            <span>{t("Account")}</span>
                        </a>
                }
                {
                    currentUser ?

                        <LiveChat />
                        :
                        <a className="col-2" href="https://direct.lc.chat/15561777/" target="_blank" rel="noreferrer" >
                            <i className="fa fa-whatsapp"></i>
                            <span>{t("LiveChat")}</span>
                        </a>
                }
            </div>
        </div>
    );
};

export default Footer;
