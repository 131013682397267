import React from "react";
import { useParams } from "react-router-dom";
import EWallet from "../components/EWallet";

const EWalletTransfer = ({ ewalletsList, getMerchantEWalletslist, ewalletid,item}) => {


    return (
        <div className="payment-container">
            <div className="card card-style pt-0" style={{ background: "transparent" }}>
                <div className="content mt-3">
                    <div className="tabs tabs-pill" id="tab-group-2">
                        <EWallet ewalletid={ewalletid} ewalletsList={ewalletsList} item={item} getMerchantEWalletslist={getMerchantEWalletslist} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EWalletTransfer;
