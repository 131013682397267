import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import authService from "../../../service/auth.service";
import tokenService from "../../../service/token.service";
import Loading from "../../../components/Loading";
import { useTranslation } from "react-i18next";

const initialValues = {
    bank: "",
    account_number: "",
    account_name: "",
};

const Save = ({ userId, setAuth }) => {
    const [values, setValues] = useState(initialValues);
    const [banks, setBanks] = useState([]);
    const [validations, setValidations] = useState({
        bank: "",
        account_number: "",
        account_name: "",
    })
    const [isLoading, setLoading] = useState(false);

    const {
        bank: bankVal,
        account_name: nameVal,
        account_number: phoneVal,
    } = validations

    const navigate = useNavigate();
    const {t} = useTranslation();

    const getBanks = async () => {
        const banklist = await authService.getBanklist();
        let formattedbanklist = [];
        banklist?.data?.map((bk) => {
            if (bk) {
                formattedbanklist.push({
                    value: bk?.id,
                    label: bk?.name,
                    icon: bk?.icon,
                    number: bk?.number
                });
            }
        })

        setBanks(formattedbanklist);
    }


    const handleSave = async e => {
        e.preventDefault();
        setLoading(true);

        if (!userId) return;

        try {
            await authService.activate({ userId, values });
            const uservalue = tokenService.getRegisterUser();
            await authService.login({ username: uservalue.username, password: uservalue.password });
            navigate('/');
            setAuth(true)
        } catch (err) {
            console.log("err => ", err);
            let bank_msg;
            let account_name_msg;
            let account_number_msg;
            if (err?.response?.data?.bank) {
                bank_msg = err.response.data.bank;
            }
            if (err?.response?.data?.account_name) {
                account_name_msg = err.response.data.account_name;
            }
            if (err?.response?.data?.account_number) {
                account_number_msg = err.response.data.account_number;
            }
            if (err?.response?.data?.non_field_errors) {
                account_number_msg = err.response.data.non_field_errors;
            }

            setValidations({ ...validations, bank: bank_msg, account_number: account_number_msg, account_name: account_name_msg });
        }
        setLoading(false);
    }

    useEffect(() => {
        getBanks();
    }, [])

    const handleChange = (e) => {
        setValues(values => {
            return { ...values, [e.target.name]: e.target.value };
        });
    };

    const handleBKChange = (e) => {
        setValues(values => {
            return { ...values, bank: e.value };
        });
    };

    const handleBKAccChange = (e) => {
        const RE = /[0-9\b]$/;
        if (RE.test(e.currentTarget.value) || e.currentTarget.value === '') {
            setValues({ ...values, account_number: e.currentTarget.value });
        }
    }

    return (
        <div className="min-h-full d-flex flex-col justify-center">
            <div className="save-wrap form-label form-icon mb-3">
                <Select
                    options={banks}
                    formatOptionLabel={bk => (
                        <div className="flex items-center gap-1" key={bk?.id}>
                            <img src={bk?.icon} alt="" className="w-10 h-10" />
                            <span>{bk?.label}</span>
                        </div>
                    )}
                    onChange={handleBKChange}
                    aria-label="Floating label select example"
                    placeholder={`--${t("Select_a_Bank")}--`}
                />
                <div className={`${bankVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{bankVal}</div>
            </div>
            <div className="form-label form-icon mb-3">
                <div>
                    <input
                        className={`custom-input-p form-control rounded-xs`}
                        name="account_name"
                        type="text"
                        id="c1"
                        placeholder={t("Full_Name")}
                        onChange={handleChange}
                    />
                </div>
                <div className={`${nameVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{nameVal}</div>
            </div>
            <div className="form-label form-icon mb-3">
                <div>
                    <input
                        className={`custom-input-p form-control rounded-xs`}
                        name="account_number"
                        type="text"
                        id="c3"
                        placeholder={t("Bank") + " " + t("Account_Number")}
                        onChange={handleBKAccChange}
                        value={values?.account_number}
                    />
                </div>
                <div className={`${phoneVal === '' ? 'valid-feedback' : 'invalid-feedback'} `}>{phoneVal}</div>
            </div>
            <button className="btn btn-full bg-blue-dark rounded-xs text-uppercase font-700 w-full btn-s mt-4"
                onClick={handleSave}
            >
                {t("Save_And_Continue")}
            </button>
            {
                isLoading && <Loading />
            }
        </div>
    );
};

export default Save;
