import React from "react";
import { useTranslation } from "react-i18next";

const BankItem = ({ item }) => {
  const {t} = useTranslation();
  return (
    <div className="bg-gray-100 mb-3 p-2 rounded-md">
      <div className="flex flex-row items-center">
        <img
          src={item.icon}
          alt="bank-thumbnail"
          className="w-20 h-16 rounded-md"
          style={{objectFit: "cover" }}
        />
        <div className="ml-4">
          <p className="text-lg font-medium text-black">
            {t("Bank")}: {item.metaData.name}
          </p>
          <p className="text-md font-medium">{t("Account")}: {item.number}</p>
        </div>
      </div>
      <p className="bg-slate-200 font-medium text-black mt-1">{t("Account")} {t("Holder")}: {item.name}</p>
    </div>
  );
};

export default BankItem;
