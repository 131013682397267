import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import userService from "../service/user.service";
import OnlineTransfer from "./OnlineTransfer";
import ManualTransfer from "./ManualTransfer";
import EWalletTransfer from "./EWalletTransfer";
import { useTranslation } from "react-i18next";

const Deposit = () => {
    const [ewalletsList, setEWalletsList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [depositType, setDepositType] = useState('')
    const [selected, setSelected] = useState(null)
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        getCouponTypes();
        getMerchantEWalletslist();
    }, [])

    const getCouponTypes = async () => {
        setLoading(true);
        let typelists;
        try {
            typelists = await userService.getCouponTypelist();
        } catch (err) {
            console.log(err);
        }
        let typeArr = [];
        typelists?.data?.map((type) => {
            let typedata = {
                id: '',
                name: ''
            };
            typedata.id = type[0];
            typedata.name = type[1];
            typeArr.push(typedata);
        })

        setLoading(false);
    }

    const getMerchantEWalletslist = async () => {
        setLoading(true);
        let ewallets;
        try {
            ewallets = await userService.getMerchantEWallets();
        } catch (err) {
            console.log(err);
        }
        setEWalletsList(ewallets?.data);
        setLoading(false);
    }

    return (
        <div className="deposit-container">
            <br />
            <div className="list-group list-custom list-menu-large" style={{ backgroundColor: 'transparent', }}>
                <div><img src={require("../images/Method.png")} alt="" style={{ width: "100%", height: "auto" }} /></div>
                <br />
                <h2 class="my-10 font-bold">{t("Select_Transfer_Type")}</h2>

                <div className="d-flex justify-content-between p-2" style={{ border: "1px solid #cbcbcb" }}>
                    <a id="nav-online" className={`white-item-color d-flex flex-col align-items-center justify-content-center`} onClick={() => {
                        setDepositType('online')
                    }}>
                        <div className="d-flex justify-center" >
                            <span className="icon icon-xl ot-custom-icon">
                            </span>
                        </div>
                        <div>{t("Instant")}</div>
                    </a>
                    <a id="nav-manual" className={`d-flex flex-col align-items-center justify-content-center`} onClick={() => {
                        setDepositType('manual')
                    }}>
                        <div className="d-flex justify-center" >
                            <span className="icon icon-xl mt-custom-icon">
                            </span>
                        </div>
                        <div>{t("Manual")}</div>
                    </a>
                    {ewalletsList && ewalletsList.map((item, index) => {
                        return (
                            <a id="nav-manual" className={`d-flex flex-col align-items-center justify-content-center`} key={index} onClick={() => {
                                setDepositType(`ewallet`)
                                setSelected({ id: item.id, item })
                            }}>
                                <div className="d-flex justify-center">
                                    <img src={item?.icon} alt="" className="icon icon-xl" />
                                </div>
                                <div>{String(item.name).toLocaleUpperCase()}</div>
                            </a>
                        )
                    })}
                </div>
                {
                    isLoading && <Loading />
                }
            </div>
            {
                depositType === 'online' && <OnlineTransfer />
            }
            {
                depositType === 'manual' && <ManualTransfer />
            }
            {
                depositType === 'ewallet' && <EWalletTransfer getMerchantEWalletslist={getMerchantEWalletslist} ewalletid={selected?.id} ewalletsList={ewalletsList} item={selected?.item} />
            }
            {/* <EWallet /> */}
        </div>
    );
};

export default Deposit;
