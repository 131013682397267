import files from "./files.json"
import countriesData from "./countries.json"

export const languages = files.map(file => {
    return {
        key: file,
        file: require(`./lang/${file}.json`),
    }
})

let countries = {}
countriesData.forEach(country => {
    countries[country.country]= {
        flag: require(`./flags/${country.country}-flag.png`),
        default_lang: country.default_lang,
        supported_languages: country.supported_languages,
    }
});

export {countries}