import React, { useState, useEffect } from 'react'
import QRCode from 'qrcode'
import { Icon } from '@iconify/react';
import UserService from '../service/user.service'
import Loading from "../components/Loading";

export default function QRCodeSection() {
    const [referralLink, setReferralLink] = useState('');
    const [QRCodeLink, setQRCodeLink] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [copyIcon, setCopyIcon] = useState('solar:copy-linear');
    const [username, setUsername] = useState('');

    const getUserInfo = async () => {
        try {
            const userInfo = await UserService.getBalance()
            const referralLink = userInfo.data.referral_link
            setUsername(userInfo.data.username)
            return referralLink
        } catch (err) {
            console.log(err)
        }
    }

    const generateQRCode = async (referral) => {
        try {
            const qrCode = await QRCode.toDataURL(referral)
            setQRCodeLink(qrCode)
        } catch (err) {
            console.error(err)
        }
    }

    const handleCopyLink = (link) => {
        navigator.clipboard.writeText(link)
        setCopyIcon('akar-icons:double-check')
        setTimeout(() => {
            setCopyIcon('solar:copy-linear')
        }, 1000)
    }

    useEffect(() => {
        const initialSetting = async () => {
            setIsLoading(true)
            const referral = await getUserInfo()
            setReferralLink(referral)
            await generateQRCode(referral)
            setIsLoading(false)
        }
        return initialSetting()
    }, [])

    return (
        <div className='pt-80'>
            {isLoading && <Loading />}
            {QRCodeLink &&
                <div className='position-relative'>
                    <div className='position-absolute qrcode-username'>{username}</div>
                    <img src={QRCodeLink} alt='qrcode' className='w-100 rounded-lg' draggable={false} />
                </div>
            }
            {referralLink && <div className='w-100 bg-slate-200 p-2 rounded-lg mt-3 d-flex items-center justify-between'>
                <div className='light-bg rounded-lg p-2 d-flex items-center justify-between w-100'>
                    <div className='referral-link'>
                        {referralLink}
                    </div>
                    <Icon icon={copyIcon} fontSize={30} className='referralLink-copy' onClick={() => handleCopyLink(referralLink)} />
                </div>
            </div>}
        </div>
    )
}
