import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import authService from "../../service/auth.service";
import { margin } from "@mui/system";
import { left } from "@popperjs/core";
import { useTranslation } from "react-i18next";

const initialValues = {
  username: "",
  password: "",
};

const SignIn = ({setAuth}) => {
  const location = useLocation();

  const [values, setValues] = useState(initialValues);
  const [validations, setValidations] = useState({
    username: "",
    password: "",
    refresh: "",
  });

  const {
    username: nameVal,
    password: passVal,
    refresh: refreshVal,
  } = validations;

  const navigate = useNavigate();
  const {t} = useTranslation();

  const handleChange = (e) => {
    setValues((values) => {
      return { ...values, [e.target.name]: e.target.value };
    });
  };

  const submit = async (e) => {
    e.preventDefault();

    try {
      await authService.login(values);
      setValidations({
        username: "",
        password: "",
        refresh: "",
      });
      setValues(initialValues)
      setAuth(true)
      navigate("/");
    } catch (err) {
      let username_msg;
      let password_message;
      let refresh_msg;
      console.log(err);
      if (err?.response?.data?.username) {
        username_msg = err.response.data.username;
      }
      if (err?.response?.data?.detail) {
        refresh_msg = err.response.data.detail;
      }
      if (err?.response?.data?.password) {
        password_message = err.response.data.password;
      }
      setValidations({
        ...validations,
        username: username_msg,
        password: password_message,
        refresh: refresh_msg,
      });
    }
  };

  const getReferralCode = () => {
    const searchParams = new URLSearchParams(location.search);
    const myParam = searchParams.get("referrer");
    if (myParam) {
      localStorage.setItem("referralCode", myParam);
    } else {
      localStorage.removeItem("referralCode");
    }
  };

  useEffect(() => {
    getReferralCode();
  }, []);

  return (
    <div className="min-h-full d-flex flex-col justify-center">
      <div className="page-content footer-clear">
        <div className="card card-style" style={{ background: "transparent"}}>
         <img src={'../../f8logo.png'} className="F8logo" style={{width: '30%', background: 'transparent',alignSelf:"center"}} />
        <br/><br/><br/>
          <div className="test123" style={{display:"flex",background:"transparent",height:"50px",alignItems:"center",borderRadius: "20px"}}>
        <button
                onClick={() => navigate("/signin")}
                className="left-column"
                style={{
                  height: "40px",
                  width:"40%",
                  borderRadius: "20px",
                  fontWeight: "bold",
                  color: "white",
                  background:"rgb(49, 140, 214)",
                  marginLeft:"5%"
                  

                }}
              >
                {t("LOGIN")}
              </button>
              <button
                onClick={() => navigate("/signup")}
                className="right-column"
                style={{
                  height: "40px",
                  width:"40%",
                  borderRadius: "20px",
                  fontWeight: "bold",
                  color: "white",
                  background:"rgb(173, 216, 230)",
                  marginLeft:"auto",
                  marginRight:"5%"
                }}
              >
                {t("REGISTER")}
              </button>
              </div>
          <div className="content">
            <form onSubmit={submit}>
              {/* <h1
                className="mb-0 pt-2"
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#318cd6",
                }}
              >
                WELCOME
              </h1>
              <p
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "30px",
                  position: "relative",
                  top: "4px",
                }}
              >
                Login
              </p> */}
              <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                <div>
                  <i className="fa fa-user-circle-o font-13"></i>
                  <input
                    name="username"
                    type="text"
                    className="form-control rounded-xs"
                    id="c1"
                    placeholder={t("Username")}
                    onChange={handleChange}
                    autoCapitalize="none"
                  />
                  <label htmlFor="c1" className="color-theme">
                    {t("Username")}
                  </label>
                </div>
                <div
                  className={`${
                    nameVal === "" ? "valid-feedback" : "invalid-feedback"
                  } `}
                >
                  {nameVal}
                </div>
              </div>
              <div className="form-custom form-label form-border form-icon mb-4 bg-transparent">
                <div>
                  <i className="fa fa-asterisk font-13"></i>
                  <input
                    name="password"
                    type="password"
                    className="form-control rounded-xs"
                    id="c2"
                    placeholder={t("Password")}
                    onChange={handleChange}
                    autoCapitalize="none"
                  />
                  <label htmlFor="c2" className="color-theme">
                    {t("Password")}
                  </label>
                </div>
                <div
                  className={`${
                    passVal === "" ? "valid-feedback" : "invalid-feedback"
                  } `}
                >
                  {passVal}
                </div>
                <div
                  className={`${
                    refreshVal === "" ? "valid-feedback" : "invalid-feedback"
                  } `}
                >
                  {refreshVal}
                </div>
              </div>
              <div className="form-check form-check-custom">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="type"
                  value=""
                  id="c2a"
                />
                <label className="form-check-label font-12" htmlFor="c2a">
                  {t("Remember_This_Account")}
                </label>
                <i className="is-checked color-highlight font-13 fa fa-circle-o pt-3"></i>
                <i className="is-unchecked color-highlight font-13 fa fa-circle pt-3"></i>
              </div>
              <button
                type="submit"
                className="w-100 btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"
                style={{
                  height: "50px",
                  borderRadius: "15px",
                  fontWeight: "bold",
                  color: "rgb(49, 140, 214)",
                }}
              >
                {t("LOGIN")}
              </button>
                <div className="text-center">
                  <a
                    // href="/forget-password"
                    onClick={() => navigate("/forget-password")}
                    className="font-15 color-theme opacity-60 pt-4 d-block"
                    style={{ fontWeight: "bold", color: "rgb(49, 140, 214)",cursor:"pointer" }}
                  >
                    {t("Forget_Password")}
                  </a>
                </div>
                {/* <div className="col-6 text-end">
                  <a className="font-15 color-theme opacity-60 pt-4 d-block">
                    <div
                      onClick={() => navigate("/signup")}
                      style={{
                        cursor: "pointer",
                        fontWeight: "bold",
                        color: "rgb(49, 140, 214)",
                      }}
                    >
                      Register
                    </div>
                  </a>
                </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
