import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import Lottie from "lottie-react";
import { Icon } from "@iconify/react";
import Gallery from "../components/Gallery";
import Slider from "../components/Slider";
import Marquee from "react-fast-marquee";
import Tablist from "../components/Tablist";
import BalanceSection from "../components/BalanceSection";
import UserService from "../service/user.service";
import AuthService from "../service/auth.service";
import Loading from "../components/Loading";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import LiveAnimation from "../images/animation_lli1kx0e.json";

import TransitionsModal from "../components/common/TransitionsModal";
import Promotions from "./Promotions";

const EASYTECH_GAME = process.env.REACT_APP_EASYTECH;
const JOKER_GAME = process.env.REACT_APP_JOKER;
const HABANERO_GAME = process.env.REACT_APP_HABANERO;
const PLAYTECH_GAME = process.env.REACT_APP_PLAYTECH;
const JDB_GAME = process.env.REACT_APP_JDB;
const SPADE_GAMING = process.env.REACT_APP_SPADE_GAMING;
const JILI_GAMING = process.env.REACT_APP_JILI;
const ASIA_GAMING = process.env.REACT_APP_ASIA_GAMING;
const LIVE22_GAMING = process.env.REACT_APP_LIVE22_GAMING;
const PRAGMATIC_GAMING = process.env.REACT_APP_PRAGMATIC_GAMING;
const VPOWER_GAMING = process.env.REACT_APP_VPOWER_GAMING;

const Home = ({ getWalletInfo, walletInfo, isRefresh }) => {
  // const [tabNum, setTabNum] = useState("");
  const {t} = useTranslation();
  const [tabNum, setTabNum] = useState("news");
  const [images, setImages] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [newsfeeds, setNewsfeeds] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [gamelist, setGamelist] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [gameData, setGameData] = useState();
  const [text, setText] = useState("");
  const [androidGameUrl, setAndroidGameUrl] = useState("");
  const [ios32GameUrl, setios32GameUrl] = useState("");
  const [ios64GameUrl, setios64GameUrl] = useState("");
  const [startedGameList, setStartedGameList] = useState([]);
  const [playModal, setPlayModal] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const [selectedCredit, setSelectedCredit] = useState("");
  const [noticesImages, setNoticesImages] = useState([]);
  const [currentPopup, setCurrentPopup] = useState(0);
  const [notices, setNotices] = useState(null);
  const [isAuth, setAuth] = useState(true);
  const [page, setPage] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [isPage, setIsPage] = useState(true);
  const [ezSelect, setEzSelect] = useState(null);
  const [playText, setPlayText] = useState(t("Play_Now"));
  const [selectedProvider, setSelectedProvider] = useState("");
  const [startedGame, setStartedGame] = useState(null);
  const [gameError, setGameError] = useState(null);


  const iframeRef = useRef(null);

  const navigate = useNavigate();

  var userAgent = navigator.userAgent.toLowerCase();

  const getSliderImgs = async () => {
    setLoading(true);
    const games = await UserService.getSliderImages();
    let imgArr = [];
    games?.data?.map((game) => {
      if (game.image) {
        imgArr.push({ imgSrc: game?.image });
      }
    });
    setImages(imgArr);
    setLoading(false);
  };

  const getTabslist = async () => {
    setLoading(true);
    const tabslist = await UserService.getTablist();
    setTabs(tabslist?.data);
    setLoading(false);
  };

  const getNewsfeedsList = async () => {
    setLoading(true);
    try {
      const newslist = await UserService.getNewsfeeds();
      setNewsfeeds(newslist?.data || []);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (ezSelect && isPage) {
  //     console.log("fetching items for page, ", page);
  //     getEasytechProductlist(page);
  //   }
  // }, [page, isPage]);

  // useEffect(() => {
  //   const baseLimit = 24;
  //   function handleScroll() {
  //     const { scrollTop, clientHeight, scrollHeight } =
  //       document.documentElement;

  //     if (scrollTop + clientHeight >= scrollHeight) {
  //       // Bottom reached, trigger your action here
  //       if (!fetching) {
  //         setPage((pre) => pre + baseLimit);
  //       }
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [fetching]);

  const getProductlist = async () => {
    if (tabNum === "news") return;

    setLoading(true);
    try {
      const productlist = await UserService.getProducts(tabNum);
      setGamelist(productlist?.data);
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const getEasytechProductlist = async (page, _game) => {
    setLoading(true);
    // setFetching(true);
    // if (page === 0) {
    //   setLoading(true);
    // }

    let game = selectedGame;
    if (_game) {
      game = _game;
    }
    try {
      let products;
      let limitPerPage = 24;
      if (game.name === EASYTECH_GAME) {
        products = await UserService.getEztechProducts(0, page + limitPerPage);
      } else if (game.name === JOKER_GAME) {
        products = await UserService.getJokerProducts(0, page + limitPerPage);
      } else if (game.name === HABANERO_GAME) {
        products = await UserService.getHabaneroProducts(
          0,
          page + limitPerPage
        );
      } else if (game.name === PLAYTECH_GAME) {
        products = await UserService.getPlaytechProducts(
          0,
          page + limitPerPage
        );
      } else if (game.name === JDB_GAME) {
        products = await UserService.getJDBProducts(0, page + limitPerPage);
      } else if (game.name === SPADE_GAMING) {
        products = await UserService.getSpadeGamingProducts(
          0,
          page + limitPerPage
        );
      } else if (game.name === JILI_GAMING) {
        products = await UserService.getJiliProducts(0, page + limitPerPage);
      } else if (game.name === ASIA_GAMING) {
        products = await UserService.getAsiaGamingProducts(
          0,
          page + limitPerPage
        );
      } else if (game.name === LIVE22_GAMING) {
        products = await UserService.getLive22Products(0, page + limitPerPage);
      } else if (game.name === PRAGMATIC_GAMING) {
        products = await UserService.getPragmaticProducts(
          0,
          page + limitPerPage
        );
      } else if (game.name === VPOWER_GAMING) {
        products = await UserService.getVpowerProducts(0, page + limitPerPage);
      }

      let data = products.data;

      if (
        game.name === HABANERO_GAME ||
        game.name === JDB_GAME ||
        game.name === JILI_GAMING ||
        game.name === JOKER_GAME ||
        game.name === LIVE22_GAMING ||
        game.name === PLAYTECH_GAME ||
        game.name === PRAGMATIC_GAMING ||
        game.name === SPADE_GAMING ||
        game.name === VPOWER_GAMING
      ) {
        data = data.map((item) => {
          return {
            gameid: String(item.c_h5_code),
            id: game.id,
            credit_type: item.credit_type,
            name: item.c_name,
            provider: item.c_prod_name,
            thumbnail: item.c_image,
            title: item.c_name,
          };
        });
      }

      if (data.length === 0) {
        setIsPage(false);
      }
      if (data.length !== 0) {
        setIsPage(true);
        if (game.name !== EASYTECH_GAME) {
          setSelectedProvider(data[0].provider);
        }
        if (game.name === EASYTECH_GAME) {
          setSelectedGame({
            ...game,
            credit_type: data[0].credit_type,
          });
        }
      }
      if (page === 0) {
        setGamelist(data);
      } else {
        setGamelist(data);
      }
    } catch (err) {
      console.log(err);
    }
    // setTimeout(() => {
    //   setFetching(false);
    // }, 800);
    setLoading(false);
  };

  const getGameStartedList = async () => {
    try {
      const userInfo = await UserService.getBalance();
      const startedGameList = await UserService.getStartedGames(
        userInfo.data.user.id
      );
      setStartedGameList(startedGameList.data);
      if (startedGameList.data.length === 0) {
        localStorage.setItem("startedGame", null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleProduct = async (game) => {
    const localUser = localStorage.getItem("user");
    if (!localUser) {
      return navigate("/signin");
    }
    console.log("handle", game.name, PLAYTECH_GAME)
    if (
      game.name === EASYTECH_GAME ||
      game.name === JOKER_GAME ||
      game.name === HABANERO_GAME ||
      game.name === PLAYTECH_GAME ||
      game.name === JDB_GAME ||
      game.name === SPADE_GAMING ||
      game.name === JILI_GAMING ||
      game.name === LIVE22_GAMING ||
      game.name === PRAGMATIC_GAMING ||
      game.name === VPOWER_GAMING
    ) {
      if (startedGameList.map((item) => item.product_id).includes(game.id)) {
        return await StopProduct(game.id);
      }
      setPlayText(t("Start"));
      setPlayModal(true);
    } else {
      if (startedGameList.map((item) => item.gameid).includes(game.gameid)) {
        return await StopProduct(game.id);
      }
      setPlayText(t("Play_Now"));
      if (ezSelect) {
        setPlayModal(false);
        initiateProduct("h5", game.gameid);
      } else {
        setPlayModal(true);
      }
    }
    setSelectedGame(game);
    if (game.credit_type === "CC") {
      if (walletInfo.balance > 0) {
        setSelectedCredit("CA");
      } else if (walletInfo.chips_balance > 0) {
        setSelectedCredit("CH");
      }
    } else if (game.credit_type === "CA") {
      setSelectedCredit("CA");
    } else if (game.credit_type === "CH") {
      setSelectedCredit("CH");
    }
  };

  const handleGameLaunch = async () => {
    if (
      tabNum === "slots" &&
      (selectedGame.name === EASYTECH_GAME ||
        selectedGame.name === JOKER_GAME ||
        selectedGame.name === HABANERO_GAME ||
        selectedGame.name === PLAYTECH_GAME ||
        selectedGame.name === JDB_GAME ||
        selectedGame.name === SPADE_GAMING ||
        selectedGame.name === JILI_GAMING ||
        selectedGame.name === LIVE22_GAMING ||
        selectedGame.name === PRAGMATIC_GAMING ||
        selectedGame.name === VPOWER_GAMING)
    ) {
      getEasytechProductlist(500);
      // setTimeout(() => {
        setEzSelect(selectedGame);
      // }, 500);
    } else {
      const { ltype, id } = selectedGame;
      initiateProduct(ltype, id);
    }
    setPlayModal(false);
  };

  const initiateProduct = async (ltype, id) => {
    if (startedGameList.map((item) => item.product_id).includes(id)) {
      await StopProduct(id);
    }

    setLoading(true);

    try {
      if (ltype === "h5") {
        const win = window.open("about:blank");
        let res;
        if (ezSelect) {
          if (ezSelect.name === EASYTECH_GAME) {
            res = await UserService.startEasytechProduct(id, selectedCredit);
            win.location = res.data.url.url;
          } else if (
            ezSelect.name === JOKER_GAME ||
            ezSelect.name === HABANERO_GAME ||
            ezSelect.name === PLAYTECH_GAME ||
            ezSelect.name === JDB_GAME ||
            ezSelect.name === SPADE_GAMING ||
            ezSelect.name === JILI_GAMING ||
            ezSelect.name === LIVE22_GAMING ||
            ezSelect.name === PRAGMATIC_GAMING ||
            ezSelect.name === VPOWER_GAMING
          ) {
            res = await UserService.startEasytogoProduct(
              ezSelect.id,
              id,
              selectedCredit
            );
            win.location = res.data.url;
          }
        } else {
          res = await UserService.startProduct(id, selectedCredit);
          win.location = res.data.url;
        }
        setSelectedGame(id);
        await getWalletInfo();
      }

      if (ltype === "app") {
        const res = await UserService.startProduct(id, selectedCredit);
        setGameData(res.data);
        if (res.data.name === "Pussy888") {
          setAndroidGameUrl(
            `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `pussy888://pussy888.com/?user=${res.data.username}&password=${res.data.password}`
          );
        } else if (res.data.name === "918kiss") {
          setAndroidGameUrl(
            `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `lobbykiss://lobbykiss?account=${res.data.username}&password=${res.data.password}`
          );
        } else if (res.data.name === "Mega888") {
          setAndroidGameUrl(
            `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `lobbymegarelease://?account=${res.data.username}&password=${res.data.password}`
          );
        } else if (res.data.name === "Evo888") {
          setAndroidGameUrl(
            `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
          );
          setios32GameUrl(
            `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
          );
          setios64GameUrl(
            `evo888android://lobbyevoandroid?account=${res.data.username}&password=${res.data.password}`
          );
        }
        setOpen(true);
        setGameError(null);
        await getWalletInfo();
      }
      await getGameStartedList();
    } catch (err) {
      console.log(err);
      if (err && err.message) {
        setGameError({ msg: err.message });
      }
    }
    setLoading(false);
  };

  const StopProduct = async (id, urltype) => {
    setLoading(true);
    try {
      await UserService.stopProduct(id);
      await getWalletInfo();
      await getGameStartedList();
      setGameError(null);
      setStartedGame(null)
    } catch (err) {
      console.log(err);
      setGameError({ msg: err.message });
    }
    setLoading(false);
  };

  const copy = async (copyText) => {
    if (copyText !== "") {
      await navigator.clipboard.writeText(copyText);
      setText(copyText);
    }
  };

  const handleCloseNotice = () => {
    setCurrentPopup((prevIndex) => prevIndex + 1);
  };

  
  const setupStartedGame = (games, startedGames) => {
    startedGames.map((sGame) => {
      games.map((game) => {
        if (sGame.gameid === game.gameid) {
          const startedGame = JSON.stringify(game);
          localStorage.setItem("startedGame", startedGame);
        }
      });
    });
  };

  useEffect(() => {
    getSliderImgs();
    getTabslist();
    getNewsfeedsList();
    if (iframeRef?.current) {
      const setIframeHeight = function (e) {
        var key = e.message ? "message" : "data";
        var data = e[key];
        iframeRef.current.style.height = `${data}px`;
      };
      window.addEventListener("message", setIframeHeight, false);
      return () => {
        window.removeEventListener("message", setIframeHeight, false);
      };
    }
  }, [iframeRef.current]);

  useEffect(() => {
    if (text === "") {
      setText("");
    }
  }, [text]);

  useEffect(() => {
    setGamelist([]);
    if (tabNum === "easytech") {
      const game = {
        urltype: "EZTECH88",
        name: "Easytech88",
        thumbnail: "",
      };
      setSelectedGame(game);
      setSelectedProvider("Easytech");
      setEzSelect(game);
      handleProduct(game);
      setTimeout(() => {
        getEasytechProductlist(500, game);
      }, 500);
    } else {
      getProductlist();
      setPage(0);
      setEzSelect(null);
      setSelectedProvider("");
    }
    getGameStartedList();
    setIsPage(true);
  }, [tabNum]);

  useEffect(() => {
    if (gamelist.length !== 0 && startedGameList.length !== 0) {
      setupStartedGame(gamelist, startedGameList);
    }
  }, [gamelist.length, startedGameList.length]);

  useEffect(() => {
    const sGame = localStorage.getItem("startedGame");
    if (sGame) {
      const parsedGame = JSON.parse(sGame);
      setStartedGame(parsedGame);
    }
  }, [localStorage.getItem("startedGame")]);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }
  }, [localStorage.getItem("user")]);

  const getNotices = async () => {
    const data = await UserService.getNoticesImages();
    if (data.data && Array.isArray(data.data)) {
      setNoticesImages(data.data.filter((notice) => notice.type === "static"));
      const popups = data.data.filter((notice) => {
        if (notice.type == "popup") {
          if (!notice.is_once) {
            let popup_isonce_onsite = JSON.parse(
              sessionStorage.getItem("popup_isonce_onsite")
            );
            if (!popup_isonce_onsite) {
              popup_isonce_onsite = [];
            }
            if (!popup_isonce_onsite.includes(notice.id)) {
              popup_isonce_onsite.push(notice.id);
              sessionStorage.setItem(
                "popup_isonce_onsite",
                JSON.stringify(popup_isonce_onsite)
              );
              return true;
            }
            return false;
            // return true;
          } else {
            let popup_isonce = JSON.parse(localStorage.getItem("popup_isonce"));
            if (!popup_isonce) {
              popup_isonce = [];
            }
            if (!popup_isonce.includes(notice.id)) {
              popup_isonce.push(notice.id);
              localStorage.setItem(
                "popup_isonce",
                JSON.stringify(popup_isonce)
              );
              return true;
            }
          }
        } else {
          return false;
        }
      });
      setNotices({
        popups,
      });
    }
  };
  useEffect(() => {
    getNotices();
    const handleOnLoad = (e) => {
      sessionStorage.removeItem("popup_isonce_onsite");
    };
    window.addEventListener("load", handleOnLoad);

    return () => {
      window.removeEventListener("load", handleOnLoad);
    };
  }, []);

  const resetSlots = () => {
    if (tabNum === "slots") {
      setPage(0);
      setEzSelect(null);
      getGameStartedList();
      setSelectedProvider("");
      setGamelist([]);
      getProductlist();
    }
  };

  return (
    <div className="home-container" style={{ paddingBottom: "5.5rem" }}>
      <Header
        getWalletInfo={getWalletInfo}
        walletInfo={walletInfo}
        setCurrentUserGlobal={setCurrentUser}
        setAuth={setAuth}
      />
      <br />
      <br />
      <div style={{ marginTop: "1rem" }}>
        <Slider images={images} />
      </div>
      {!currentUser && (
        <div className="flex flex-row items-center justify-between gap-3">
          <button
            className="w-full btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"
            onClick={() => {
              navigate("/signin");
            }}
          >
            {t("LOGIN")}
          </button>
          <button
            className="w-full btn btn-full gradient-highlight shadow-bg shadow-bg-s mt-4"
            onClick={() => {
              navigate("/signup");
            }}
          >
            {t("REGISTER")}
          </button>
        </div>
      )}
      <div className="noticebar">
        <Marquee>
          {newsfeeds.map((feed, newsindex) => (
            <span key={newsindex}>
              {feed.text}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          ))}
        </Marquee>
      </div>
      <Tablist
        tablist={tabs}
        tabNum={tabNum}
        setTabNum={setTabNum}
        resetSlots={resetSlots}
      />
      <BalanceSection
        getWalletInfo={getWalletInfo}
        walletInfo={walletInfo}
        isRefresh={isRefresh}
        startedGameList={startedGameList}
        StopProduct={StopProduct}
        setStartedGameList={setStartedGameList}
      />
      {tabNum === "news" ? (
        // <iframe
        //   src="https://app-news-iframe.f8play.com"
        //   id="iframe-news"
        //   width="100%"
        //   style={{ borderRadius: 10, overflow: "visible", height: "445px" }}
        //   ref={iframeRef}
        // />
        <Promotions />
      ) : (
        // <div
        //   className="d-flex flex-column"
        //   style={{ borderRadius: "10px", background: "#fff", gap: "10px" }}
        // >
        //   {noticesImages && noticesImages.length
        //     ? noticesImages.map((image) => {
        //         return (
        //           <div
        //             key={image.id}
        //             style={{ borderRadius: "10px", width: "100%" }}
        //           >
        //             <img
        //               src={image.image_sm}
        //               alt=""
        //               style={{ width: "100%", height: "auto" }}
        //             />
        //           </div>
        //         );
        //       })
        //     : null}
        // </div>
        <Gallery
          gamelist={gamelist}
          handleProduct={handleProduct}
          StopProduct={StopProduct}
          startedGameList={startedGameList}
          fetching={fetching}
          page={page}
          setPage={setPage}
          ezSelect={ezSelect}
          provider={selectedProvider}
        />
      )}
      {isLoading && <Loading />}
      {isOpen && (
        <TransitionsModal centered open={true} onClose={() => setOpen(false)} footer={[]}>
          <div className="download-app-info">
            <div className="balance-wrap">
              {t("Balance")} {t("In_Game")}: {gameData?.credit ? gameData.credit : 0.0}
            </div>
            <div className="accountInfo-wrap">
              <div className="d-flex justify-between my-10">
                <div className="d-flex flex-col justify-center text-12 font-bold text-uppercase">
                  {t("Username")}:
                </div>
                <div className="clipboard-wrap d-flex justify-between bg-silver">
                  <div>{gameData?.username}</div>
                  <div onClick={() => copy(gameData?.username)}>
                    {text === gameData?.username ? (
                      <Icon
                        icon="material-symbols:check"
                        width={20}
                        color="green"
                      />
                    ) : (
                      <Icon icon="bxs:copy" width={20} />
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-between">
                <div className="d-flex flex-col justify-center text-12 font-bold text-uppercase">
                  {t("Password")}:
                </div>
                <div className="clipboard-wrap d-flex justify-between bg-silver">
                  <div>{gameData?.password}</div>
                  <div onClick={() => copy(gameData?.password)}>
                    {text === gameData?.password ? (
                      <Icon
                        icon="material-symbols:check"
                        width={20}
                        color="green"
                      />
                    ) : (
                      <Icon icon="bxs:copy" width={20} />
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-between w-full">
                {gameData?.url?.android[0].includes("https://") ? (
                  <a
                    href={`${gameData?.url?.android}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="download-apk-btn d-flex">
                      <div className="d-flex flex-col justify-center">
                        {t("Android")} {t("Download")}
                      </div>
                      <div className="d-flex flex-col justify-center">
                        <Icon icon="uil:android" width={20} />
                      </div>
                    </div>
                  </a>
                ) : (
                  <a
                    href={`https://${gameData?.url?.android}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="download-apk-btn d-flex">
                      <div className="d-flex flex-col justify-center">
                        {t("Android")} {t("Download")}
                      </div>
                      <div className="d-flex flex-col justify-center">
                        <Icon icon="uil:android" width={20} />
                      </div>
                    </div>
                  </a>
                )}

                {gameData?.url?.ios[0].includes("https://") ? (
                  <a
                    href={`${gameData?.url?.ios}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="download-apk-btn d-flex">
                      <div className="d-flex flex-col justify-center">
                        {t("iOS")} {t("Download")}
                      </div>
                      <div className="d-flex flex-col justify-center">
                        <Icon icon="ic:baseline-apple" width={20} />
                      </div>
                    </div>
                  </a>
                ) : (
                  <a
                    href={`https://${gameData?.url?.ios}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="download-apk-btn d-flex">
                      <div className="d-flex flex-col justify-center">
                        {t("iOS")} {t("Download")}
                      </div>
                      <div className="d-flex flex-col justify-center">
                        <Icon icon="ic:baseline-apple" width={20} />
                      </div>
                    </div>
                  </a>
                )}
              </div>
              {userAgent.indexOf("android") > -1 ? (
                <div className="d-flex justify-between w-full">
                  <a
                    className="w-full"
                    href={androidGameUrl ? androidGameUrl : "/"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="play-btn">
                      <div className="d-flex flex-col justify-center">
                        {t("Play_Now")}
                      </div>
                    </div>
                  </a>
                </div>
              ) : (
                <>
                  {userAgent.indexOf("iphone") > -1 &&
                  window.innerWidth <= 320 ? (
                    <div className="d-flex justify-between w-full">
                      <a
                        className="w-full"
                        href={ios32GameUrl ? ios32GameUrl : "/"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="play-btn">
                          <div className="d-flex flex-col justify-center">
                            {t("Play_Now")}
                          </div>
                        </div>
                      </a>
                    </div>
                  ) : (
                    <div className="d-flex justify-between w-full">
                      <a
                        className="w-full"
                        href={ios64GameUrl ? ios64GameUrl : "/"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="play-btn">
                          <div className="d-flex flex-col justify-center">
                            {t("Play_Now")}
                          </div>
                        </div>
                      </a>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </TransitionsModal>
      )}
      {selectedGame && (
        <TransitionsModal
          title={selectedGame.name}
          footer={[]}
          open={playModal}
          onClose={() => {
            setPlayModal(false);
            setEzSelect(null);
          }}
          style={{ textAlign: "center" }}
        >
          <div>
            <div className="flex flex-row justify-center">
              <img
                src={selectedGame.image_mobile}
                alt={selectedGame.name}
                style={{ width: "150px", borderRadius: "1rem" }}
              />
            </div>
            <p className="text-md font-medium mt-3">
              {t("Note")}: {t("The_Game")} {t("Supports")}{" "}
              {selectedGame.credit_type === "CA" && "only CASH. "}
              {selectedGame.credit_type === "CH" && "only CHIPS. "}
              {selectedGame.credit_type === "CC" &&
                "Cash and Chips both types. "}
              {t("Please_select_an_option")}.
            </p>
            <div className="flex flex-row justify-center items-center my-3 gap-x-3">
              {(selectedGame.credit_type === "CC" ||
                selectedGame.credit_type === "CA") && (
                <div
                  onClick={() => setSelectedCredit("CA")}
                  className="d-flex items-center justify-center gap-2
            balance-account w-1/2 p-3"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "1rem",
                    borderColor:
                      selectedCredit === "CA" ? "rgb(92, 149, 224)" : "#ccc",
                    background:
                      selectedCredit === "CA"
                        ? "rgba(92, 149, 224, .2)"
                        : "transparent",
                  }}
                >
                  <span className="icon icon-lg cash-custom-icon"></span>
                  <div
                    className={`d-flex flex-col items-end text-stone-900`}
                    style={{ fontWeight: "bold" }}
                  >
                    <h6>{t("Cash")}</h6>
                    <h6>{currentUser ? walletInfo?.balance : "0.00"}</h6>
                  </div>
                </div>
              )}
              {(selectedGame.credit_type === "CC" ||
                selectedGame.credit_type === "CH") && (
                <div
                  onClick={() => setSelectedCredit("CH")}
                  className="d-flex items-center justify-center gap-2 balance-account w-1/2 p-3"
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "1rem",
                    borderColor:
                      selectedCredit === "CH" ? "rgb(92, 149, 224)" : "#ccc",
                    background:
                      selectedCredit === "CH"
                        ? "rgba(92, 149, 224, .2)"
                        : "transparent",
                  }}
                >
                  <span className="icon icon-lg chips-custom-icon"></span>
                  <div
                    className={`d-flex flex-col items-end text-stone-900`}
                    style={{ fontWeight: "bold" }}
                  >
                    <h6>{t("Chips")}</h6>
                    <h6>{currentUser ? walletInfo?.chips_balance : "0.00"}</h6>
                  </div>
                </div>
              )}
            </div>
            <button
              onClick={handleGameLaunch}
              className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
            font-700 w-100 btn-s mt-2 h-12`}
            >
              {playText}
            </button>
          </div>
        </TransitionsModal>
      )}
      {notices &&
      notices.popups &&
      notices.popups.length &&
      notices.popups[currentPopup] ? (
        <TransitionsModal
          title={t("Notices")}
          open={notices.popups.length}
          onClose={() => handleCloseNotice()}
          style={{ overflow: "hidden" }}
        >
          <div style={{ width: "100%" }}>
            <img
              style={{ width: "100%" }}
              src={notices.popups[currentPopup].image_sm}
              alt=""
            />
          </div>
        </TransitionsModal>
      ) : null}  
      <TransitionsModal
          title={selectedGame ? selectedGame.name : "Game Error"}
          open={gameError}
          onClose={() => setGameError(false)}
          style={{ textAlign: "center", padding: "1rem", borderRadius: "15px" }}
        >
          <div>
            <div className="flex flex-row justify-center">
              <Icon icon="codicon:error" color="#f27474" width={70} />
              {/* <img
                src={selectedGame.image_mobile}
                alt={selectedGame.name}
                style={{ width: "150px", borderRadius: "1rem" }}
              /> */}
            </div>
            <p className="text-md font-medium mt-3">
              {gameError && gameError.msg ? gameError.msg : null}
            </p>
            <button
              onClick={() => setGameError(false)}
              className={`btn btn-sm bg-blue-dark rounded-xs text-uppercase
            font-700 w-100 btn-l mt-3 h-12`}
            >
              OK
            </button>
          </div>
        </TransitionsModal>
      <AnimatePresence>
          {!ezSelect && startedGame && (
            <motion.div
              initial={{ y: "100%" }}
              animate={{ y: 0 }}
              exit={{ y: 150 }}
              transition={{ type: "spring", stiffness: 100, damping: 10 }}
              className="started-game-container"
              style={{
                position: "fixed",
                bottom: 68,
                left: 0,
                width: "100%",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
                zIndex: 100,
                padding: "8px",
              }}
            >
              <div className="started-game-footer">
                <div className="started-game-context">
                  <img
                    src={startedGame.thumbnail}
                    className="started-game-img"
                  />
                  <div>
                    <h4>{startedGame.title}</h4>
                    <div className="provider-box">
                      <p>{startedGame.provider}</p>
                      <Lottie
                        className="live-json"
                        animationData={LiveAnimation}
                        loop={true}
                        autoplay={true}
                      />
                    </div>
                  </div>
                </div>
                <button onClick={() => StopProduct(startedGame.gameid)}>
                  Close
                </button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
    </div>
  );
};

export default Home;
