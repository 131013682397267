import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { languages } from "./languages"
import files from "./files.json"

const resources = {}
languages.forEach(lng => {
    resources[lng.key] = {
        translation: lng.file
    }
})
export const initializeI18next = () => {
    i18n.use(initReactI18next)
        .init({
            resources: resources,
            lng: files[0],
            fallbackLng: files[0],
            interpolation: {escapeValue: false}
        });
}