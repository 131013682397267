import React, { useEffect, useState } from "react";
import {LiveChatWidget , useWidgetState  } from '@livechat/widget-react';
import { useTranslation } from "react-i18next";

const LiveChat = () => {
    const widgetState = useWidgetState();
    const [widgetIsReady, setWidgetIsReady] = useState(false);
    const {t} = useTranslation();

    if (widgetState) {
          console.log(widgetState.availability)
          console.log(widgetState.visibility)
    }
    useEffect(() => {
        if (widgetState && widgetState.visibility === "hidden") {
          window.location.reload();
        }
      }, [widgetState]);
    
    const handleclick = () => {
        setWidgetIsReady(true);
    }


    return(
        <a className="col-2" href="#" onClick={handleclick}>
            {widgetIsReady && (
                <LiveChatWidget
                license='15561777'
                visibility="maximized"
                />
            )}
        <i className="fa fa-whatsapp">
        </i><span>{t("LiveChat")}</span>
        </a>
    )

};

export default LiveChat;