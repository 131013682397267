import { useEffect, useState } from "react";
import userService from "../service/user.service";
import BankItem from "./listItems/BankItem";
import { Spin } from "antd";

const BankList = ({ setFullName }) => {
  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);
  const [myBanks, setBanks] = useState([
    {
      name: "",
      icon: "",
      number: "",
      metaData: {
        name: "",
      },
    },
  ]);

  const fetchWallet = async () => {
    setLoading(true);
    try {
      const wallet = await userService.getBalance();
      const userId = wallet.data.user.id;
      setUserId(userId);

      // fetch customer bank details
      const customerBanks = await userService.getCustomBanks();
      const customerBank = customerBanks.data;
      if (customerBank.length === 0) {
        return;
      }

      let banks = [];
      customerBank.map((bank) =>
        banks.push({
          ...bank,
          metaData: {
            name: "",
          },
        })
      );

      const bank = customerBank[0];
      setFullName(bank.name);

      let rafactoredBanks = [];
      // fetch bank meta data
      const bankData = await userService.getBankDetails(bank.bank);
      if (bankData.status === 200) {
        banks.map((b) =>
          rafactoredBanks.push({
            ...b,
            metaData: bankData.data,
          })
        );
        setBanks(rafactoredBanks);
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchWallet();
  }, [localStorage.getItem("user")]);

  return (
    <div>
      {loading ? (
        <div className="w-full h-64 flex flex-row justify-center items-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          {myBanks.map((item, index) => (
            <BankItem key={index} item={item} />
          ))}
        </>
      )}
    </div>
  );
};

export default BankList;
